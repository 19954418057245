import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormGroupInput from './dummy/forms/FormGroupInput';
import ButtonSubmit from './dummy/forms/ButtonSubmit';
import {USERNAME, SEND, AUTH_FORGOT_PASSWORD, AUTH_GENERIC, RECOVER_PASSWORD, ALREADY_HAVE_ACCOUNT, LOGIN, RECOVER_GREETING_1, RECOVER_GREETING_2, LOGIN_TERMS} from "../res/textConstants";
import {validateForgotPasswordForm} from "../helpers/validator";
import {forgotPassword} from "../helpers/API";
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {Link} from "react-router-dom";
import Auth from './dummy/forms/Auth';
import {checkRecaptcha} from "../helpers/utils";
import {startButtonLoading, endButtonLoading} from "../actions/loading";
import {withNamespaces} from 'react-i18next';

class ForgotPassword extends Component {
    state = {
        username: '',
        auth: false,
        invalidField: ''
    };

    clearInvalidField = () => {
        this.setState(prev => ({
            invalidField: []
        }));
    };

    setInvalidField = (newInvalid) => {
        this.setState(prev => ({
            invalidField: newInvalid
        }));
    };

    checkIfFieldInvalid = (fieldName) => {
        return fieldName === this.state.invalidField;
    };

    onChange = (event) => {
        const { name, value } = event.target;

        this.setState(prev => ({
            [name]: value
        }));
    };

    onCheckboxChange = (event) => {
        const { name, checked } = event.target;

        this.setState(prev => ({
            [name]: checked
        }));
    };

    submit = (event) => {
        event.preventDefault();

        this.clearInvalidField();
        const validationResult = validateForgotPasswordForm(this.state.username, this.state.auth);
        if(validationResult.valid) {
            this.props.dispatch(startButtonLoading());
            checkRecaptcha('forgotpassword')
                .then(token => {
                    forgotPassword(this.state.username, token)
                        .then(() => {
                            this.props.history.push('/senha/envio');
                            this.props.dispatch(endButtonLoading());
                        })
                    .catch(error => {
                        alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
                        this.props.dispatch(endButtonLoading());
                    });
                });
        } else {
            this.setInvalidField(validationResult.invalidField);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div className="login">
                <div className="login-container">
                    <div className="left">
                        <h1>{t(RECOVER_PASSWORD)}</h1>
                        <div className="subtitle">
                            <p>{t(RECOVER_GREETING_1)}</p>
                            <p>
                                {t(RECOVER_GREETING_2)}
                                <a href="https://www.improxy.pt/termos-de-uso/" rel="noopener noreferrer" target="_blank">{t(LOGIN_TERMS)}</a>
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <FormGroupInput
                                className='form-control'
                                label={t(USERNAME)}
                                type='email'
                                name='username'
                                invalidField={this.checkIfFieldInvalid('username')}
                                value={this.state.username}
                                onChange={this.onChange}/>

                            <Auth
                                label={[t(AUTH_FORGOT_PASSWORD), " ", <strong key='strong-key'>{t(AUTH_GENERIC)}</strong>]}
                                checked={this.state.auth}
                                onChange={this.onCheckboxChange}
                            />

                            <ButtonSubmit
                                className='m-button'
                                label={t(SEND)}
                                loading={this.props.loading}
                                onClick={this.submit}/>
                        </form>
                        <div className="already-have-account">
                            <p>{t(ALREADY_HAVE_ACCOUNT)} <Link to='/'>{t(LOGIN)}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({loading}) {
    return {
        loading: loading.button > 0
    }
}

export default connect(mapStateToProps)(withNamespaces('translations')(ForgotPassword));
