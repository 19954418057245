import React from 'react';
import PropTypes from 'prop-types';
import {FaStar} from 'react-icons/fa'

const Stars = (props) => {
    const stars = [];

    let i;
    const length = props.stars || 0;
    for (i = 0; i < length; i++) {
        stars.push(`star-${i}`);
    }

    return (
        <div className='stars'>
            {length > 0 && (
                <div>
                    {stars.map(star => (
                        <FaStar key={star}/>
                    ))}
                </div>
            )}

            {length === 0 && (
                <div>-</div>
            )}
        </div>
    );
};

Stars.propTypes = {
    stars: PropTypes.number
};

export default Stars;
