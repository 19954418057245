import thunk from 'redux-thunk';
import logger from './logger';

import {applyMiddleware} from 'redux';

let mdware;

if (process.env.NODE_ENV === 'development') {
    mdware = applyMiddleware(thunk, logger);
} else {
    mdware = applyMiddleware(thunk);
}

export default mdware;
