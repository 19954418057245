import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    LOGIN,
    USERNAME,
    PASSWORD,
    SIGNUP_ACTIVATION,
    CONFIRM_NEW_PASSWORD,
    RECOVER_PASSWORD,
    ALREADY_HAVE_ACCOUNT,
    SIGNUP_ACTIVATION_SUCCESS,
    RECOVER_PASSWORD_SUCCESS,
    LOGIN_GREETING_1,
    LOGIN_GREETING_2,
    LOGIN_TERMS,
    ACTIVATE
} from "../res/textConstants";
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {withNamespaces} from "react-i18next";
import {validateEmail, validatePasswordSetForm} from "../helpers/validator";
import FormGroupInput from "./dummy/forms/FormGroupInput";
import ButtonSubmit from "./dummy/forms/ButtonSubmit";
import {Link} from "react-router-dom";
import {endButtonLoading, startButtonLoading} from "../actions/loading";
import {checkRecaptcha} from "../helpers/utils";
import {accountActivation, recoverPassword} from "../helpers/API";

class PasswordSet extends Component {
    state = {
        tokenValid: null,
        username: '',
        password: '',
        confirmPassword: ''
    };

    userToken = null;
    type = null;
    title = null;
    apiCall = null;

    componentDidMount() {
        const {username, token} = this.props.match.params;

        if(!username || !token || !validateEmail(username)) {
            this.goHome();
            return;
        }

        this.setState({
            username: username
        });

        this.userToken = token;

        if(this.props.type === 'signup') {
            this.type = 'signup';
            this.title = SIGNUP_ACTIVATION;
            this.apiCall = accountActivation;
        } else {
            this.type = 'recover';
            this.title = RECOVER_PASSWORD;
            this.apiCall = recoverPassword;
        }
    }

    goHome = () => {
        this.props.history.push('/');
    };

    clearInvalidField = () => {
        this.setState(prev => ({
            invalidField: []
        }));
    };

    setInvalidField = (newInvalid) => {
        this.setState(prev => ({
            invalidField: newInvalid
        }));
    };

    checkIfFieldInvalid = (fieldName) => {
        return fieldName === this.state.invalidField
    };

    onChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prev => ({
            [name]: value
        }));
    };

    submit = (event) => {
        event.preventDefault();

        this.clearInvalidField();
        const validationResult = validatePasswordSetForm(this.state.username, this.state.password, this.state.confirmPassword);
        if(validationResult.valid) {
            this.props.dispatch(startButtonLoading());
            checkRecaptcha('account_activation')
            .then(token => {
                this.apiCall(this.state.username, this.state.password, this.userToken, token)
                .then(() => {
                    const message = this.type === 'signup' ? SIGNUP_ACTIVATION_SUCCESS : RECOVER_PASSWORD_SUCCESS;
                    alertDispatcher(ALERT_MESSAGE_TYPE.SUCCESS, message);
                    this.props.history.push(`/login/${this.state.username}`);
                    this.props.dispatch(endButtonLoading());
                })
                .catch(error => {
                    alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
                    this.props.dispatch(endButtonLoading());
                });
            });
        } else {
            this.setInvalidField(validationResult.invalidField);
        }
    };

    render() {
        const {t} = this.props;

        return (
            <div className="login">
                <div className="login-container">
                    <div className="left">
                        <h1>{t(this.title)}</h1>
                        <div className="subtitle">
                            <p>{t(LOGIN_GREETING_1)}</p>
                            <p>
                                {t(LOGIN_GREETING_2)}
                                <a href="https://www.improxy.pt/termos-de-uso/" rel="noopener noreferrer" target="_blank">{t(LOGIN_TERMS)}</a>
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <FormGroupInput
                                className='form-control'
                                label={t(USERNAME)}
                                type='email'
                                name='username'
                                invalidField={this.checkIfFieldInvalid('username')}
                                value={this.state.username}
                                onChange={this.onChange}
                                disabled={true}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(PASSWORD)}
                                type='password'
                                name='password'
                                invalidField={this.checkIfFieldInvalid('password')}
                                value={this.state.password}
                                onChange={this.onChange}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(CONFIRM_NEW_PASSWORD)}
                                type='password'
                                name='confirmPassword'
                                invalidField={this.checkIfFieldInvalid('confirmPassword')}
                                value={this.state.confirmPassword}
                                onChange={this.onChange}/>

                            <ButtonSubmit
                                className='m-button'
                                label={t(ACTIVATE)}
                                loading={this.props.loading}
                                onClick={this.submit}/>
                        </form>

                        <div className="already-have-account">
                            <p>{t(ALREADY_HAVE_ACCOUNT)} <Link to='/'>{t(LOGIN)}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps({loading}, props) {
    return {
        loading: loading.button > 0,
        ...props
    }
}

export default connect(mapStateToProps)(withNamespaces('translations')(PasswordSet));
