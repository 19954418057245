import Alert from 'react-s-alert';
import {getTranslatedText} from './utils';

export default function alertDispatcher(type, message, timeout = 2000) {
    /**
     * @param   {string}      type    Type of the error message (warning, info, error).
     * */

    message = getTranslatedText(message);

    Alert[type](`${message}`, {
        position: 'top-right',
        effect: 'slide',
        timeout,
        offset: 0
    });
};
