import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropertyRow from './dummy/property/PropertyRowTeste';
import selectProperties from '../selectors/properties';
import {handleSetUserProperties} from "../actions/propertiesUser";

class PropertyListTeste extends Component {

  componentDidMount() {
    if(this.props.properties.length === 0) {
        this.getProperties(this.props.type);
    }
  }

  getProperties(type) {
    this.props.dispatch(handleSetUserProperties());
  }

  render() {
    const {t} = this.props;

    return(
        <div>
            <div className="property-list property">

                <div className="container-fluid property-list-container">
                    <Fragment>
                        {this.props.properties.map(property => (

                            <PropertyRow
                                key={property.id}
                                property={property}
                            />

                        ))}
                    </Fragment>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state, props) => {

  console.log("state: ");
  console.log(state);
  
  return {
    properties: selectProperties(state.properties, state.filters)
  };

};

export default connect(mapStateToProps)(PropertyListTeste);