import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './components/App';
import {Provider} from 'react-redux';
import configureStore from './store/configureStore';

import getVisibleProperties from './selectors/properties';

import {I18nextProvider} from 'react-i18next';
import i18n from './res/i18n';
import {basename} from "./config/config";

import 'normalize.css/normalize.css';
import '../node_modules/bootstrap/dist/css/bootstrap-grid.css';
import './styles/css/index.css';

const store = configureStore();
const state = store.getState();

const visibleProperties = getVisibleProperties(state.properties, state.filters);

const jsx = (
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Router basename={basename}>
                <App/>
            </Router>
        </I18nextProvider>
    </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'));
