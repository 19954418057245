import React from 'react';
import PropTypes from 'prop-types';

const FormGroupInput = (props) => {

    // TODO: QUANDO OS DADOS DO CAMPO NAO SAO VALIDOS HA UM PROP QUE ASSINALA
    // props.invalidField = true;

    return(
        <div className='form-group'>
            <input
                className={props.avoidAnimation ?('with-value ' + props.className) : (props.value ? 'with-value ' + props.className : props.className)}
                type={props.type || 'text'}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}/>
            <label htmlFor={props.name}>
                {props.label}
            </label>
        </div>
    );
};

FormGroupInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    invalidField: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    avoidAnimation: PropTypes.bool
};

export default FormGroupInput;
