import React, {Fragment} from 'react';
import {withNamespaces} from "react-i18next";
import {REFERENCE} from '../.././../res/textConstants';
import {FaBed, FaMapMarkerAlt} from 'react-icons/fa';
import NumberFormat from 'react-currency-format';
import PropTypes from 'prop-types';

const PropertyFilter = (props) => {
    const {
        t
    } = props;

    return (
        <Fragment>
            <div className="property-filter">
                <form>

                    <div className="row">
                        <div className="col-12 property-filter-row">
                            <div className='form-group purpose'>
                                <select 
                                    className='form-control'
                                    name='purpose' 
                                    value={props.purpose}
                                    onChange={props.onFilterChange}>
                                        {props.purposesAvailable.map(purpose => (
                                            <option key={purpose.id} value={purpose.id}>
                                                {purpose.text.pt}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div className='form-group group'>
                                <select 
                                    className='form-control'
                                    name='group' 
                                    value={props.group} 
                                    onChange={props.onFilterChange}>
                                        {props.groupsAvailable.map(group => (
                                            <option key={group.id} value={group.id}>
                                                {group.text.pt}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div className='form-group type'>
                                <select 
                                    className='form-control'
                                    name='type' 
                                    value={props.type} 
                                    disabled={props.typesDisabled}
                                    onChange={props.onFilterChange}>
                                        {props.typesAvailable.map(type => (
                                            <option key={type.id} value={type.id}>
                                                {type.text.pt}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <div className='form-group location'>
                                <FaMapMarkerAlt />
                                <input
                                    className='form-control location-control'
                                    type='text'
                                    name='location'
                                    placeholder='Localização'
                                    value={props.location}
                                    onChange={props.onFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 property-filter-row">

                            <div className="form-parent-group">
                                <div className='form-group'>
                                    <FaBed />
                                    <select 
                                        className='form-control beds-control'
                                        name='bedsMin' 
                                        value={props.bedsMin} 
                                        disabled={props.bedsDisabled}
                                        onChange={props.onFilterChange}>
                                            {props.bedsMinAvailable.map( bed => (
                                                <option key={bed.id} value={bed.id}>
                                                    {bed.value}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                
                                <div className='form-group'>
                                    <FaBed />
                                    <select 
                                        className='form-control beds-control'
                                        name='bedsMax' 
                                        value={props.bedsMax} 
                                        disabled={props.bedsDisabled}
                                        onChange={props.onFilterChange}>
                                            {props.bedsMaxAvailable.map(bed => (
                                                <option key={bed.id} value={bed.id}>
                                                    {bed.value}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form-parent-group">
                                <div className="form-group price">
                                    <NumberFormat 
                                        className='form-control'
                                        placeholder="Preço Min" 
                                        value={props.priceMin} 
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','} 
                                        suffix=" €" 
                                        onValueChange={props.onFilterPriceMinChange} />
                                </div>
            
                                <div className="form-group price">
                                    <NumberFormat 
                                        className='form-control'
                                        placeholder="Preço Max" 
                                        value={props.priceMax} 
                                        thousandSeparator={'.'} 
                                        decimalSeparator={','} 
                                        suffix=" €" 
                                        onValueChange={props.onFilterPriceMaxChange} />
                                </div>
                            </div>

                            <div className="form-parent-group">
                                <div className='form-group'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='reference'
                                        placeholder={t(REFERENCE)}
                                        value={props.reference}
                                        onChange={props.onFilterChange}
                                    />
                                </div>

                                <div className='form-group'>
                                    <select 
                                        className='form-control'
                                        name='orderBy' 
                                        value={props.orderBy} 
                                        onChange={props.onFilterChange}>
                                            {props.orderByJSON.map(order => (
                                                <option key={order.id} value={order.id}>
                                                    {order.value}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </form>
            </div>
        </Fragment>
    );
};

PropertyFilter.propTypes = {
    reference: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
    purposesAvailable: PropTypes.array.isRequired,
    group: PropTypes.string.isRequired,
    groupsAvailable: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    typesAvailable: PropTypes.array.isRequired,
    typesDisabled: PropTypes.bool.isRequired,
    bedsMin: PropTypes.string.isRequired,
    bedsMinAvailable: PropTypes.array.isRequired,
    bedsMax: PropTypes.string.isRequired,
    bedsMaxAvailable: PropTypes.array.isRequired,
    bedsDisabled: PropTypes.bool.isRequired,
    priceMin: PropTypes.string.isRequired,
    priceMax: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onFilterPriceMinChange: PropTypes.func.isRequired,
    onFilterPriceMaxChange: PropTypes.func.isRequired
};

export default withNamespaces('translations')(PropertyFilter);
