import React from 'react';
import PropTypes from 'prop-types';
import Stars from './Stars';
import {withNamespaces} from "react-i18next";
import {DATE, VISIT_PRICE, VISIT_LOCATION, CONDITION, VISIT_POSITIVE, VISIT_NEGATIVE, VISIT_PROPERTY} from '../../res/textConstants';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const Visits = (props) => {
    const visits = props.visits;

    const starFormatter = (cell) => {
        return (<Stars stars={ cell }/>);
    };

    const sortCaret = (order, column) => {
        if (!order) return (<FaSort />);
        else if (order === 'asc') return (<FaSortUp />);
        else if (order === 'desc') return (<FaSortDown />);
        return null;
    };

    const {t} = props;

    const columns = [{
        dataField: 'start_date',
        text: `${t(DATE)}`,
        sort: false,
        sortCaret: sortCaret,
        align: 'center',
        headerStyle: (colum, colIndex) => {
            return { width: '8%' };
        }
    }, {
        dataField: 'price',
        text: `${t(VISIT_PRICE)}`,
        formatter: starFormatter,
        sort: false,
        sortCaret: sortCaret,
        align: 'center',
        headerClasses: 'star-column'
    }, {
        dataField: 'location',
        text: `${t(VISIT_LOCATION)}`,
        formatter: starFormatter,
        sort: false,
        sortCaret: sortCaret,
        align: 'center',
        headerClasses: 'star-column'
    }, {
        dataField: 'conservation_state',
        text: `${t(CONDITION)}`,
        formatter: starFormatter,
        sort: false,
        sortCaret: sortCaret,
        align: 'center',
        headerClasses: 'star-column'
    }, {
        dataField: 'obs',
        text: `${t(VISIT_POSITIVE)}`,
        sort: false,
        headerClasses: 'point-column'
    }, {
        dataField: 'obs2',
        text: `${t(VISIT_NEGATIVE)}`,
        sort: false,
        headerClasses: 'point-column'
    }];

    const defaultSorted = [{
        dataField: 'start_date',
        order: 'desc'
    }];

    return (
        <div>
            <div className="card-property">
                <h3>{t(VISIT_PROPERTY)}</h3>
                <BootstrapTable
                    bootstrap4
                    keyField='id'
                    data={ visits }
                    columns={ columns }
                    defaultSorted={ defaultSorted }
                    rowClasses='table-visit-row'
                >
                </BootstrapTable>
            </div>
        </div>
    );
};

Visits.propTypes = {
    visits: PropTypes.array.isRequired
};

export default withNamespaces('translations')(Visits);
