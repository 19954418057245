import React from 'react';
import {LOADING} from "../../res/textConstants";
import {withNamespaces} from "react-i18next";

const Loading = (props) => {
    const {t} = props;

    return (
        <div className="loader">
            <div className="loader-item-1"></div>
            <div className="loader-item-2"></div>
            <h4>{t(LOADING)}</h4>
        </div>
    );
};

export default withNamespaces('translations')(Loading);