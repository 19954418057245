import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";

const PropertyImage = (props) => {
    const {photo} = props;

    return (
        <Fragment>
            {photo && <div className="property-image" style={{ "backgroundImage": `url(${photo})`  }} ></div>}
        </Fragment>
    );
};

PropertyImage.propTypes = {
    property: PropTypes.string
};

export default withNamespaces('translations')(PropertyImage);
