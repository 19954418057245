
// Get visible properties

export default (properties, { text }) => {
  return properties.filter((property) => {
    const textMatch = "" //property.description.toLowerCase().includes(text.toLowerCase());

    return textMatch;
  });
};
