import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {formatDate, spaceExpression} from "../../../helpers/utils";
import {withNamespaces} from "react-i18next";
import {
    BUSINESS_ACTIONS,
    PUBLISH_WEBSITE,
    PUBLISH_PORTAL,
    VISIT_VIRTUAL,
    CONTRACT_START_DATE,
    PROPERTY_FEATURES,
    FEATURE_AREA,
    FEATURE_NUMBER_OF_ROOMS,
    FEATURE_NUMBER_OF_BATHS,
    FEATURE_NUMBER_OF_GARAGES,
    FEATURE_PRICE_PER_AREA,
    FEATURE_ENERGY_RATING,
    WEBSITE_VIEWS
} from '../../../res/textConstants';
import {FaCheck, FaTimes} from 'react-icons/fa';
import {LIST_TYPES} from "../../../config/config";

const PropertyFeatures = (props) => {
    const {t, type} = props;
    const {property} = props;

    const showOwnerInfo = type !== LIST_TYPES.PRIVATE_BUSINESSES ? true : false;

    let priceArea = Math.round(property.price / property.grossarea);
    priceArea = priceArea && !isNaN(priceArea) ? priceArea : null;

    return (
        <Fragment>

            {showOwnerInfo &&
            <div className="property-features">
                <h3>{t(BUSINESS_ACTIONS)}</h3>
                <ul>
                    {property.realtor_contract_begin_date &&
                    <li>
                        <span>{t(CONTRACT_START_DATE)}</span>
                        <span>{formatDate(property.realtor_contract_begin_date)}</span>
                    </li>
                    }

                    {property.show_in_website &&
                    <li>
                        <span>{t(PUBLISH_WEBSITE)}</span>
                        <span>{property.show_in_website ? <FaCheck/> : <FaTimes/>}</span>
                    </li>
                    }

                    {property.show_in_portals &&
                    <li>
                        <span>{t(PUBLISH_PORTAL)}</span>
                        <span>{property.show_in_portals ? <FaCheck/> : <FaTimes/>}</span>
                    </li>
                    }

                    {property.num_virtual_visit > 0 &&
                    <li>
                        <span>{t(VISIT_VIRTUAL)}</span>
                        <span>{property.num_virtual_visit}</span>
                    </li>
                    }

                    {property.website_views > 0 &&
                    <li>
                        <span>{t(WEBSITE_VIEWS)}</span>
                        <span>{spaceExpression(property.website_views.toString())}</span>
                    </li>
                    }
                </ul>
            </div>
            }

            {!showOwnerInfo &&
            <div className="property-features">
                <h3>{t(PROPERTY_FEATURES)}</h3>
                <ul>
                    {property.bedrooms &&
                    <li>
                        <span>{t(FEATURE_NUMBER_OF_ROOMS)}</span>
                        <span>{property.bedrooms}</span>
                    </li>
                    }

                    {property.bathrooms &&
                    <li>
                        <span>{t(FEATURE_NUMBER_OF_BATHS)}</span>
                        <span>{property.bathrooms}</span>
                    </li>
                    }

                    {property.garagecars &&
                    <li>
                        <span>{t(FEATURE_NUMBER_OF_GARAGES)}</span>
                        <span>{property.garagecars}</span>
                    </li>
                    }

                    {property.grossarea &&
                    <li>
                        <span>{t(FEATURE_AREA)}</span>
                        <span>{property.grossarea} m<sup>2</sup></span>
                    </li>
                    }

                    {priceArea &&
                    <li>
                        <span>{t(FEATURE_PRICE_PER_AREA)}</span>
                        <span>{priceArea} € / m<sup>2</sup></span>
                    </li>
                    }

                    {property.energy_rating &&
                    <li>
                        <span>{t(FEATURE_ENERGY_RATING)}</span>
                        <span>{property.energy_rating}</span>
                    </li>
                    }

                </ul>
            </div>
            }

        </Fragment>
    );
};

PropertyFeatures.propTypes = {
    property: PropTypes.object.isRequired
};

export default withNamespaces('translations')(PropertyFeatures);
