import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import PropertyImage from './PropertyImage';
import PropertyInfo from './PropertyInfo';
import PropertyFeatures from './PropertyFeatures';
import Visits from '../Visits';
import AgentExtended from '../AgentExtended';
import {withNamespaces} from "react-i18next";
import {VISIT_PROPERTY, ACTIVITIES_PROPERTY, SEE_LESS, SEE_MORE, FEATURE_DESCRIPTION, FEATURE_VIEW_DESCRIPTION, FEATURE_HIDE_DESCRIPTION} from '../../../res/textConstants';
import {FaCaretLeft, FaCaretRight} from 'react-icons/fa';
import {Carousel} from 'react-bootstrap';
import 'rc-collapse/assets/index.css';
import Collapse, {Panel} from 'rc-collapse';
import {FaRegImage, FaArrowAltCircleDown, FaArrowAltCircleUp} from 'react-icons/fa';
import ActivityRow from '../ActivityRow';
import {LIST_TYPES} from "../../../config/config";

const PropertyRow = (props) => {
    const {
        t,
        property,
        activeKey,
        setActivityKey,
        onChangeTable,
        accordion,
        numActivityTables,
        showCollapsable,
        type
    } = props;

    const {
        agents,
        visits,
        gallery
    } = property;

    let {activities} = property;

    const showOwnerInfo = type !== LIST_TYPES.PRIVATE_BUSINESSES ? true : false;

    if (showOwnerInfo) {
        activities = splitActivitiesList(activities, numActivityTables);
    }

    return (
        <Fragment>
            <div className="property-list-row">
                <div className="property-item-image">
                    {gallery.length > 0 && (

                        gallery.length === 1 ? (
                            <PropertyImage
                                photo={property.main_photo}
                            />
                        ) : (
                            <Carousel className="carousel-fade" interval={false} autoPlay={false}
                                      nextIcon={<FaCaretRight/>} prevIcon={<FaCaretLeft/>}>
                                {gallery.map((photo, i) => (
                                        <Carousel.Item key={`carrousel-item-${i}`}>
                                            <PropertyImage
                                                photo={photo.Image}
                                            />
                                        </Carousel.Item>
                                    )
                                )}
                            </Carousel>
                        )
                    )}

                    {gallery.length === 0 && (
                        <div className="property-image">
                            <div className="property-no-image">
                                <FaRegImage/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="property-item-content">
                    <div className="property-item-content-wrapper">
                        <PropertyInfo
                            property={property}
                        />

                        <PropertyFeatures
                            property={property}
                            type={type}
                        />

                        {/*APENAS PARA IMOVEIS DO QUAL O UTILIZADOR É PROPRIETARIO*/}
                        {showOwnerInfo && (visits.length > 0 || activities.length > 0) &&
                        <div className="property-features property-features-visits-activities">
                            <ul>
                                {visits.length > 0 &&
                                <li>
                                    <span>{t(VISIT_PROPERTY)}</span>
                                    <span>{visits.length}</span>
                                </li>
                                }

                                {activities.length > 0 &&
                                <li>
                                    <span>{t(ACTIVITIES_PROPERTY)}</span>
                                    <span>{activities.length}</span>
                                </li>
                                }
                            </ul>

                            {showCollapsable ?
                                <button className="expandIcon"
                                        onClick={() => setActivityKey(property.id.toString())}>
                                    <span>
                                        {t(SEE_LESS)}
                                    </span>
                                    <span>
                                        <FaArrowAltCircleUp/>
                                    </span>
                                </button>
                                :
                                <button className="expandIcon"
                                        onClick={() => setActivityKey(property.id.toString())}>
                                    <span>
                                        {t(SEE_MORE)}
                                    </span>
                                    <span>
                                        <FaArrowAltCircleDown/>
                                    </span>
                                </button>
                            }
                        </div>
                        }

                        {/*APENAS PARA IMOVEIS PRIVATE BUSINESS*/}
                        {!showOwnerInfo && property.description &&
                            <div className="property-features property-features-visits-activities">
                                {showCollapsable ?
                                    <button className="expandIcon"
                                        onClick={() => setActivityKey(property.id.toString())}>
                                        <span>
                                            {t(FEATURE_HIDE_DESCRIPTION)}
                                        </span>
                                        <span>
                                            <FaArrowAltCircleUp/>
                                        </span>
                                    </button>
                                    :
                                    <button className="expandIcon"
                                        onClick={() => setActivityKey(property.id.toString())}>
                                        <span>
                                            {t(FEATURE_VIEW_DESCRIPTION)}
                                        </span>
                                        <span>
                                            <FaArrowAltCircleDown/>
                                        </span>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="property-agents-list">
                    <div className="property-agents-list-wrapper">
                        {agents.length > 0 && (
                            agents.length === 1 ? (
                                agents.map(agent => (
                                        <div key={agent.id}>
                                            <AgentExtended agent={agent}/>
                                        </div>
                                    )
                                )
                            ) : (
                                <Carousel className="carousel-fade" nextIcon={<FaCaretRight/>}
                                          prevIcon={<FaCaretLeft/>}>
                                    {agents.map(agent => (
                                            <Carousel.Item key={agent.id}>
                                                <div>
                                                    <AgentExtended agent={agent}/>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    )}
                                </Carousel>
                            )
                        )}
                    </div>
                </div>

                {/* FOR PROPERTIES OWNED BY USER */}
                {showOwnerInfo && <div className="property-item-content-collapse">
                    {(visits.length > 0 || activities.length > 0) &&
                    <div className="property-item-collapse">
                        <Collapse
                            accordion={accordion}
                            onChange={onChangeTable}
                            activeKey={activeKey}
                        >

                            <Panel key={property.id.toString()}>
                                {visits.length > 0 &&
                                <div className="property-visits">
                                    <Visits visits={visits}/>
                                </div>
                                }

                                {visits.length > 0 && activities.length > 0 &&
                                <div className="space-between-tables">

                                </div>
                                }

                                {activities.length > 0 &&
                                <div className="property-activities">
                                    {activities.map((partialList, ind) => <ActivityRow key={`activities-list-${ind}`}
                                                                                       activitiesList={partialList}
                                                                                       showTitle={ind === 0}/>)}
                                </div>
                                }

                            </Panel>
                        </Collapse>
                    </div>
                    }
                </div>
                }

                {/*FOR PRIVATE BUSINESS PROPERTIES*/}
                {!showOwnerInfo && property.description && (
                    <div className="property-item-content-collapse">
                        <div className="property-item-collapse">
                            <Collapse
                                accordion={accordion}
                                onChange={onChangeTable}
                                activeKey={activeKey}
                            >
                                <Panel key={property.id.toString()}>
                                    <div className="property-description">
                                        <p className="property-description-title">{t(FEATURE_DESCRIPTION)}</p>
                                        <p>{property.description}</p>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                )}
            </div>

        </Fragment>
    );
};

function splitActivitiesList(mainList, tables) {
    let lists = [];

    mainList.map((activity, i) => {
        const tableIndex = i % tables;
        if (lists.length <= tableIndex) {
            lists.push([]);
        }

        lists[tableIndex].push(activity);
        return activity;
    });

    return lists;
}

PropertyRow.propTypes = {
    property: PropTypes.object.isRequired
};

export default withNamespaces('translations')(PropertyRow);
