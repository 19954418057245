import React from 'react';
import PropertyList from './PropertyListTeste';
import PropertyListFilters from './dummy/property/PropertyListFilters';

const PropertyDashboard = () => (
  <div>
    <PropertyListFilters />
    <PropertyList />
  </div>
);

export default PropertyDashboard;
