import {SET_APP_WIDTH} from "../actions/parameters";

export default function parameters(state = {}, action) {
    switch(action.type) {
        case SET_APP_WIDTH:
            return {
                ...state,
                width: action.width
            };

        default:
            return state;
    }
}
