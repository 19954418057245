let apiUrl;
let basenameDirectory = '/';

switch(process.env.NODE_ENV) {
    case 'development':
    case 'test':
        // Run in development or __test__ mode
        apiUrl = "http://localhost:4000/api";
        break;

    case 'production':
    default:
        // Run in production mode
        apiUrl = "https://online.gimob.net/api";
        basenameDirectory = `/${window.imp.basename}`;
        break;
}

export const basename = basenameDirectory;

export const HANDLERS = {
    COMPANY_INFO: `${apiUrl}/company.ashx`,
    USER: `${apiUrl}/user.ashx`,
    LOGIN: `${apiUrl}/login.ashx`,
    SIGNUP: `${apiUrl}/signup.ashx`,
    FORGOT_PASSWORD: `${apiUrl}/forgotPassword.ashx`,
    RECOVER_PASSWORD: `${apiUrl}/recover_password.ashx`,
    ACCOUNT_ACTIVATION: `${apiUrl}/account_activation.ashx`,
    UPDATE_PASSWORD: `${apiUrl}/updatePassword.ashx`,
    LOGOUT: `${apiUrl}/logout.ashx`,
    PROPERTIES_USER: `${apiUrl}/properties_user.ashx`,
    PROPERTIES_AGENCY: `${apiUrl}/properties_agency.ashx`,
    SEND_MESSAGE: `${apiUrl}/send_message.ashx`
};

export const REQUEST_TIMEOUT = 10000;

export const LOGOUT_ONLY_ROUTES = ['/', '/senha', 'registo'];

export const LANGUAGES_AVAILABLE = [
    {
        language: "pt-PT",
        value: "pt-PT"
    },
    {
        language: "pt-BR",
        value: "pt-BR"
    },
    {
        language: "en-GB",
        value: "en"
    }
];

export const COUNTRIES = {
    6824: 'PT',
    7556: 'BR',
    7638: 'CV',
    6825: 'ANG',
    7619: 'MOZ'
};

export const COUNTRY_LANGUAGES = {
    PT: [
        LANGUAGES_AVAILABLE[0], // pt-PT
        LANGUAGES_AVAILABLE[2]  // en
    ],
    BR: [
        LANGUAGES_AVAILABLE[1], // pt-BR
        LANGUAGES_AVAILABLE[2]  // en
    ],
    CV: [
        LANGUAGES_AVAILABLE[0], // pt-PT
        LANGUAGES_AVAILABLE[2]  // en
    ],
    ANG: [
        LANGUAGES_AVAILABLE[0], // pt-PT
        LANGUAGES_AVAILABLE[2]  // en
    ],
    MOZ: [
        LANGUAGES_AVAILABLE[0], // pt-PT
        LANGUAGES_AVAILABLE[2]  // en
    ]
};

export const LANGUAGE_WHITELIST = ["pt-PT", "pt-BR", "pt", "en"];

export const ACTIVITY_TYPE = {
    1: 'ACTIVITY_EMAIL',
    2: 'ACTIVITY_PHONE',
    3: 'ACTIVITY_TASK',
    4: 'ACTIVITY_COMMITMENT',
    5: 'ACTIVITY_PROPOSAL',
    6: 'ACTIVITY_VISIT',
    7: 'ACTIVITY_VIRTUAL_VISIT',
    8: 'ACTIVITY_ALERT'
};

export const LIST_TYPES = {
    OWN_PROPERTIES: "OWN_PROPERTIES",
    PRIVATE_BUSINESSES: "PRIVATE_BUSINESSES",
};

export const STYLE_BREAKPOINTS = [0, 576, 768, 992, 1200, 1400];

export const PROPERTY_BEDS_MIN = [
    { id: -1, value: 'Min' },
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 6, value: '6' }
];

export const PROPERTY_BEDS_MAX = [
    { id: -1, value: 'Max' },
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 1000, value: '+6' }
];

export const LIST_ORDER_BY = [
    { id: -1, value: 'Ordenar por' },
    { id: 0, value: 'Menor tipologia' },
    { id: 1, value: 'Maior tipologia' },
    { id: 2, value: 'Mais barato' },
    { id: 3, value: 'Mais caro' }
];