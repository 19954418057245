import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getFirstName} from "../helpers/utils";
import {HELLO, LOGOUT, UPDATE_PASSWORD, PROPERTY_LIST, PRIVATE_BUSINESSES} from "../res/textConstants";
import {handleRemoveAuthedUser} from '../actions/authedUser';
import { FaPowerOff, FaKey, FaHome } from 'react-icons/fa';
import {withNamespaces} from 'react-i18next';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";

class Header extends Component {

    state = {
        showMenu: false
    };

    logout = (event) => {
        this.props.dispatch(handleRemoveAuthedUser());
    };

    getPathFirstElement = (fullPath) => {
        fullPath = fullPath.split('/');
        return fullPath.length > 0 ? `/${fullPath[1]}` : '/';
    };

    toogleMenu = () => {
        this.setState(prev => ({
            showMenu: prev.showMenu ? false : true
        }));
    };

    render() {
        const company = this.props.company;
        const authedUser = this.props.authedUser;
        const {t} = this.props;

        return (
            <header>
                <Navbar expand="lg">
                    <Navbar.Brand href="#home">
                        {this.props.company && (
                            <img
                                src={company.logo}
                                alt={company.name}/>
                        )}
                    </Navbar.Brand>
                    {authedUser.id && (
                        <Fragment>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" >
                                <span></span>
                                <span></span>
                                <span></span>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">

                                    <Nav className="mr-auto">
                                        <NavItem>
                                            <span>{`${t(HELLO)} ${getFirstName(authedUser.name)}`}</span>
                                        </NavItem>

                                        {authedUser.permissions.own_properties &&
                                            <LinkContainer to="/imoveis">
                                                <NavItem><FaHome /><span>{t(PROPERTY_LIST)}</span></NavItem>
                                            </LinkContainer>
                                        }

                                        {authedUser.permissions.private_businesses &&
                                            <LinkContainer to="/negocios-privados">
                                                <NavItem><FaHome /><span>{t(PRIVATE_BUSINESSES)}</span></NavItem>
                                            </LinkContainer>
                                        }

                                        <LinkContainer to="/perfil">
                                            <NavItem><span><FaKey />{`${t(UPDATE_PASSWORD)}`}</span></NavItem>
                                        </LinkContainer>

                                        <NavItem onClick={this.logout}><FaPowerOff /><span>{`${t(LOGOUT)}`}</span></NavItem>
                                    </Nav>
                            </Navbar.Collapse>
                        </Fragment>
                    )}
                </Navbar>
            </header>
        );
    };

}

function mapStateToProps({loading, authedUser, company}, props) {
    return {
        loading: loading.button > 0,
        authedUser,
        company,
        ...props
    }
}

export default withRouter(connect(mapStateToProps)(withNamespaces('translations')(Header)));
