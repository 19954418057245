import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {formatPrice} from "../../../helpers/utils";
import {withNamespaces} from "react-i18next";

const PropertyInfo = (props) => {
    const {property} = props;

    return (
        <Fragment>
            <div className="property-info">
                {property.reference && <div className="property-reference">Ref. {property.reference}</div>}

                <h3>{property.title}</h3>

                {property.location && <div className="property-location">{property.location}</div>}

                {property.purpose && <div className="property-purpose">{property.purpose}</div>}

                {property.price && <div className="property-price">{formatPrice(property.price)}</div>}
            </div>
        </Fragment>
    );
};

PropertyInfo.propTypes = {
    property: PropTypes.object.isRequired
};

export default withNamespaces('translations')(PropertyInfo);
