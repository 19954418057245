import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropertyRow from "./dummy/property/PropertyRow";
import PropertyFilter from "./dummy/property/PropertyFilter";
import Loading from "./dummy/Loading";
import NoResults from "./dummy/NoResults";
import {
  NO_RESULTS,
  PRIVATE_BUSINESSES,
  PROPERTY_LIST
} from "../res/textConstants";
import { withNamespaces } from "react-i18next";
import { getCurrentBreakPoint } from "../helpers/utils";
import { handleSetUserProperties } from "../actions/propertiesUser";
import { handleSetAgencyProperties } from "../actions/propertiesAgency";
import { LIST_TYPES } from "../config/config";
import Pagination from "react-js-pagination";

import * as API from "../helpers/API";
import {
  PROPERTY_BEDS_MAX,
  PROPERTY_BEDS_MIN,
  LIST_ORDER_BY
} from "../config/config";

class PropertyList extends Component {
  uiRemoved = false;

  state = {
    forceLogin: false,
    accordion: false,
    showCollapsable: {},
    activeKey: [],
    location: "",
    bedsMin: "",
    bedsMinJSON: [],
    bedsMax: "",
    bedsMaxJSON: [],
    bedsDisabled: true,
    priceMin: "",
    priceMax: "",
    purpose: "",
    purposesJSON: [],
    group: "",
    groupsJSON: [],
    type: "",
    initialtypesJSON: [],
    typesJSON: [],
    typesDisabled: true,
    reference: "",
    orderBy: "",
    orderByJSON: [],
    activePage: 1
  };

  componentDidMount() {
    if (!this.props.authedUser.id) {
      this.setState(prev => ({
        forceLogin: true
      }));
      return;
    }

    const privateBusinessesPath =
      this.props.match.path === "/negocios-privados";
    if (
      privateBusinessesPath &&
      !this.props.authedUser.permissions.private_businesses
    ) {
      this.setState(prev => ({
        forceLogin: true
      }));
      return;
    }

    const ownPropertiesPath = this.props.match.path === "/imoveis";
    if (
      ownPropertiesPath &&
      !this.props.authedUser.permissions.own_properties
    ) {
      this.setState(prev => ({
        forceLogin: true
      }));
      return;
    }

    if (this.props.properties.length === 0) {
      this.getProperties(this.props.type);
    }

    API.getJsonProperties(this.props.company.country)
      .then(data => {
        const firstPurposeElement = {
          id: -1,
          properties: false,
          angariation: false,
          text: {
            pt: "Finalidade",
            en: "Purpose"
          }
        };
        const firstGroupElement = {
          id: -1,
          text: {
            pt: "Grupo",
            en: "Group"
          }
        };
        const firstTypeElement = {
          id: -1,
          angariation: false,
          bath: false,
          bed: false,
          energyRatings: false,
          garage: false,
          group: -1,
          living: false,
          text: {
            pt: "Tipo",
            en: "Type"
          }
        };
        data.groups.unshift(firstGroupElement);
        data.types.unshift(firstTypeElement);
        data.purposes.unshift(firstPurposeElement);

        this.setState({
          groupsJSON: data.groups,
          typesJSON: data.types,
          initialtypesJSON: data.types,
          purposesJSON: data.purposes,
          bedsMinJSON: PROPERTY_BEDS_MIN,
          bedsMaxJSON: PROPERTY_BEDS_MAX,
          orderByJSON: LIST_ORDER_BY
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.authedUser.id) {
      return {
        ...state,
        forceLogin: true
      };
    }

    return {
      ...state
    };
  }

  getTitle = type => {
    switch (type) {
      case LIST_TYPES.PRIVATE_BUSINESSES:
        return PRIVATE_BUSINESSES;

      case LIST_TYPES.OWN_PROPERTIES:
      default:
        return PROPERTY_LIST;
    }
  };

  getProperties(type) {
    switch (type) {
      case LIST_TYPES.PRIVATE_BUSINESSES:
        this.props.dispatch(
          handleSetAgencyProperties({
            username: this.props.authedUser.email
          })
        );
        break;

      case LIST_TYPES.OWN_PROPERTIES:
      default:
        this.props.dispatch(
          handleSetUserProperties({
            username: this.props.authedUser.email
          })
        );
        break;
    }
  }

  onChangeTable = activeKey => {
    this.setState({
      activeKey
    });
  };

  setActivityKey = key => {
    if (this.state.activeKey.indexOf(key.toString()) === -1) {
      this.setState(prev => ({
        activeKey: [...prev.activeKey, key.toString()]
      }));
    } else {
      this.setState(prev => ({
        activeKey: prev.activeKey.filter(item => {
          return item !== key;
        })
      }));
    }
    this.setState(prev => ({
      showCollapsable: {
        ...prev.showCollapsable,
        [key]: prev.showCollapsable[key] ? false : true
      }
    }));
  };

  //FILTERS
  onFilterChange = event => {
    let value = event.target.value.toString();
    let name = event.target.name.toString();
    this.setState({
      [name]: value
    });

    if (name === "purpose") this.setState({ priceMin: "", priceMax: "" });
    if (name === "group")
      this.setState({ type: "-1", bedsMin: "-1", bedsMax: "-1" });
    if (name === "type") this.setState({ bedsMin: "-1", bedsMax: "-1" });
  };

  onFilterPriceMinChange = event => {
    this.setState({
      priceMin: event.value
    });
  };

  onFilterPriceMaxChange = event => {
    this.setState({
      priceMax: event.value
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    window.scrollTo(0, 0);
  };

  render() {
    if (this.state.forceLogin && !this.uiRemoved) {
      this.uiRemoved = true;
      return <Redirect to="/" />;
    }

    if (this.props.loading.content > 0) {
      return <Loading />;
    }

    let properties = this.props.properties;
    let typesFromGroup = this.state.typesJSON;
    let bedsDisabled = true;
    let typesDisabled = true;
    let typesIDS = [];
    let locationStringArray = [];
    let itemsCountPerPage = 25;
    let itemsCount = 0;

    if (this.state.reference)
      properties = properties.filter(property =>
        property.reference
          .toLowerCase()
          .includes(this.state.reference.toLowerCase())
      );
    if (this.state.location) {
      locationStringArray = this.state.location
        .toLowerCase()
        .trim()
        .split(" ");
      locationStringArray.forEach(element => {
        properties = properties.filter(property =>
          property.completeLocation.toLowerCase().includes(" " + element)
        );
      });
    }
    if (this.state.priceMin)
      properties = properties.filter(
        property => property.price >= this.state.priceMin
      );
    if (this.state.priceMax)
      properties = properties.filter(
        property => property.price <= this.state.priceMax
      );
    if (this.state.purpose) {
      switch (this.state.purpose.toString()) {
        case "-1":
          properties = properties.filter(
            property =>
              property.purpose_id.toString() !== this.state.purpose.toString()
          );
          break;
        default:
          properties = properties.filter(
            property =>
              property.purpose_id.toString() === this.state.purpose.toString()
          );
      }
    }
    if (this.state.group) {
      switch (this.state.group) {
        case "-1":
          properties = properties.filter(
            property => property.type_id.toString() !== this.state.group
          );
          typesDisabled = true;
          break;
        default:
          typesFromGroup = this.state.typesJSON.filter(
            type =>
              type.group.toString() === this.state.group ||
              type.group.toString() === "-1"
          );
          typesIDS = typesFromGroup.map(type => {
            return type.id.toString();
          });
          properties = properties.filter(property =>
            typesIDS.includes(property.type_id.toString())
          );
          typesDisabled = false;
      }
    }
    if (this.state.type) {
      switch (this.state.type) {
        case "-1":
          properties = properties.filter(
            property => property.type_id.toString() !== this.state.type
          );
          bedsDisabled = true;
          break;
        default:
          properties = properties.filter(
            property => property.type_id.toString() === this.state.type
          );
          let typeBed = this.state.initialtypesJSON.find(
            type => type.id.toString() === this.state.type
          );
          bedsDisabled = !typeBed.bed;
      }
    }
    if (this.state.bedsMin) {
      switch (this.state.bedsMin) {
        case "-1":
          properties = properties.filter(
            property => property.bedrooms !== this.state.bedsMin
          );
          break;
        default:
          properties = properties.filter(
            property => property.bedrooms >= this.state.bedsMin
          );
      }
    }
    if (this.state.bedsMax) {
      switch (this.state.bedsMax) {
        case "-1":
          properties = properties.filter(
            property => property.bedrooms !== this.state.bedsMax
          );
          break;
        default:
          properties = properties.filter(
            property => property.bedrooms <= this.state.bedsMax
          );
      }
    }
    if (this.state.orderBy) {
      switch (this.state.orderBy.toString()) {
        case "-1":
          break;
        case "0":
          properties.sort((a, b) => (a.bedrooms > b.bedrooms ? 1 : -1));
          break;
        case "1":
          properties.sort((a, b) => (a.bedrooms < b.bedrooms ? 1 : -1));
          break;
        case "2":
          properties.sort((a, b) => (a.price > b.price ? 1 : -1));
          break;
        case "3":
          properties.sort((a, b) => (a.price < b.price ? 1 : -1));
          break;
        default:
      }
    }

    // Page properties
    itemsCount = properties.length;
    if (properties.length > itemsCountPerPage) {
      properties = properties.slice(
        (this.state.activePage - 1) * itemsCountPerPage,
        (this.state.activePage - 1) * itemsCountPerPage + itemsCountPerPage
      );
    }

    const { t } = this.props;

    return (
      <div>
        <div className="property-list property">
          <div className="container-fluid">
            <div className="row property-header">
              <div className="col">
                <h3>{t(this.getTitle(this.props.type))}</h3>
              </div>
            </div>
            {this.props.properties.length > 9 && (
              <div className="row">
                <div className="col-12 property-filter-container">
                  <PropertyFilter
                    reference={this.state.reference}
                    location={this.state.location}
                    purpose={this.state.purpose}
                    purposesAvailable={this.state.purposesJSON}
                    group={this.state.group}
                    groupsAvailable={this.state.groupsJSON}
                    type={this.state.type}
                    typesAvailable={typesFromGroup}
                    typesDisabled={typesDisabled}
                    bedsMin={this.state.bedsMin}
                    bedsMinAvailable={this.state.bedsMinJSON}
                    bedsMax={this.state.bedsMax}
                    bedsMaxAvailable={this.state.bedsMaxJSON}
                    bedsDisabled={bedsDisabled}
                    priceMin={this.state.priceMin}
                    priceMax={this.state.priceMax}
                    orderBy={this.state.orderBy}
                    orderByJSON={this.state.orderByJSON}
                    onFilterChange={this.onFilterChange}
                    onFilterPriceMinChange={this.onFilterPriceMinChange}
                    onFilterPriceMaxChange={this.onFilterPriceMaxChange}
                  />
                </div>
              </div>
            )}
          </div>

          {properties.length === 0 && this.props.authedUser.id && (
            <NoResults text={t(NO_RESULTS)} />
          )}

          <div className="col-12 property-list-container">
            <Fragment>
              {properties.map(property => (
                <PropertyRow
                  key={property.id}
                  property={property}
                  onChangeTable={this.onChangeTable}
                  setActivityKey={this.setActivityKey}
                  activeKey={this.state.activeKey}
                  accordion={this.state.accordion}
                  showCollapsable={this.state.showCollapsable[property.id]}
                  numActivityTables={getNumOfTables(
                    this.props.parameters.width
                  )}
                  type={this.props.type}
                />
              ))}
            </Fragment>
          </div>
          {itemsCount > itemsCountPerPage && (
            <div class="pager-wrapper">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={itemsCount}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function getNumOfTables(width) {
  const bkPt = getCurrentBreakPoint(width);

  switch (bkPt) {
    case 0:
    case 576:
      return 1;

    case 768:
    case 992:
      return 2;

    default:
      return 3;
  }
}

function getDefaultAgent(company) {
  return [
    {
      id: company.name,
      name: company.name,
      phone: company.subsidiary_phone,
      cell_phone: company.subsidiary_cell_phone,
      email: company.subsidiary_email
    }
  ];
}

function mapStateToProps(
  {
    loading,
    company,
    authedUser,
    propertiesUser,
    propertiesAgency,
    parameters
  },
  props
) {
  //const page = queryString.parse(props.location.search).page || 1;
  const properties =
    props.type === LIST_TYPES.PRIVATE_BUSINESSES
      ? propertiesAgency
      : propertiesUser;

  // If a property has no agents, put default values.
  properties.map(property => {
    if (property.agents.length === 0) {
      property.agents = getDefaultAgent(company);
    }

    return property;
  });

  return {
    loading,
    company,
    authedUser,
    properties,
    parameters,
    ...props
  };
}

export default connect(mapStateToProps)(
  withNamespaces("translations")(PropertyList)
);
