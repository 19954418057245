import {CLEAR_PROPERTIES_USER, SET_PROPERTIES_USER} from "../actions/propertiesUser";

export default function propertiesUser(state = [], action) {
    switch(action.type) {
        case SET_PROPERTIES_USER:
            return [
                ...state,
                ...action.properties
            ];

        case CLEAR_PROPERTIES_USER:
            return [];

        default:
            return state;
    }
}
