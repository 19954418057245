import * as TC from './textConstants';

const res_pt = {
    [TC.LOGIN]: 'Entrar',
    [TC.USERNAME]: 'Email',
    [TC.PASSWORD]: 'Senha de acesso',
    [TC.CONFIRM_PASSWORD]: 'Confirmação de senha de acesso',
    [TC.FULL_NAME]: 'Nome completo',
    [TC.SIGNUP]: 'Registro',
    [TC.SIGNUP_NOW]: 'Registre-se',
    [TC.LOGIN_TERMS]: 'termos e condições.',
    [TC.LOGIN_GREETING_1]: 'Bem vindo ao Gimob Online!',
    [TC.LOGIN_GREETING_2]: 'Ao autenticar-se nesta aplicação está a concordar com os ',
    [TC.SIGNUP_GREETING_1]: 'Bem vindo ao Gimob Online!',
    [TC.SIGNUP_GREETING_2]: 'Ao registar-se nesta aplicação está a concordar com os ',
    [TC.RECOVER_GREETING_1]: 'Bem vindo ao Gimob Online!',
    [TC.RECOVER_GREETING_2]: 'Ao recuperar senha nesta aplicação está a concordar com os ',
    [TC.LOADING]: 'Carregando...',
    [TC.BACK_HOME]: 'Voltar à página inicial',
    [TC.BACK]: 'Voltar',
    [TC.SEND]: 'Enviar',
    [TC.ACTIVATE]: 'Ativar',
    [TC.ACTIVATION_CODE]: 'Código de ativação',
    [TC.LOGOUT]: 'Sair',
    [TC.HELLO]: 'Olá',
    [TC.AGENT_TYPE]: 'Angariador',
    [TC.AGENT]: 'O angariador do seu imóvel',
    [TC.AGENTS]: 'Os angariadores do seu imóvel',
    [TC.CONTACT_PHONE]: 'Telefone',
    [TC.CONTACT_CELL_PHONE]: 'Telemóvel',
    [TC.CONTACT_EMAIL]: 'Email',
    [TC.NO_ACCOUNT]: 'Sem conta?',
    [TC.ALREADY_HAVE_ACCOUNT]: 'Já tem conta?',
    [TC.FORGOT_MY_PASSWORD]: 'Esqueci-me da senha de acesso',
    [TC.RECOVER_PASSWORD]: 'Recuperar senha',
    [TC.UPDATE_PASSWORD]: 'Alterar senha de acesso',
    [TC.SIGNUP_ACTIVATION]: 'Ativação de registo',
    [TC.DATE]: 'Data',
    [TC.CONDITION]: 'Estado',
    [TC.VISIT_PROPERTY]: 'Visitas efetuadas ao imóvel',
    [TC.VISIT_DATE]: 'Data da visita',
    [TC.VISIT_INTEREST]: 'Interesse',
    [TC.VISIT_PRICE]: 'Preço',
    [TC.VISIT_LOCATION]: 'Localização',
    [TC.VISIT_CONDITION]: 'Estado de conservação',
    [TC.VISIT_POSITIVE]: 'Pontos positivos',
    [TC.VISIT_NEGATIVE]: 'Pontos negativos',
    [TC.VISIT_VIRTUAL]: 'Enviado por email:',
    [TC.WEBSITE_VIEWS]: 'Visualizações no website',
    [TC.BEDROOM]: 'quarto',
    [TC.BEDROOMS]: 'quartos',
    [TC.NO_RESULTS]: 'Não foram encontrados imóveis.',
    [TC.OLD_PASSWORD]: 'Senha de acesso atual',
    [TC.NEW_PASSWORD]: 'Nova senha de acesso',
    [TC.CONFIRM_PASSWORD]: 'Confirmação de senha de acesso',
    [TC.CONFIRM_NEW_PASSWORD]: 'Confirmação de nova senha',
    [TC.AUTH_FORGOT_PASSWORD]: 'Declaro que sou titular destes dados pessoais e autorizo o uso dos mesmos para efeitos de recuperação da minha senha de acesso.',
    [TC.AUTH_SIGNUP]: 'Declaro que sou titular destes dados pessoais e autorizo a passagem dos mesmos para poder ter acesso à área de clientes.',
    [TC.AUTH_GENERIC]: '(Para podermos receber o seu pedido terá de confirmar esta opção)',
    [TC.VALIDATION_NEEDED]: "Confirmação necessária",
    [TC.VALIDATION_NEEDED_INFO_1]: "O seu pedido de registo foi enviado para a agência.",
    [TC.VALIDATION_NEEDED_INFO_2]: "Nos próximos dias receberá instruções sobre como proceder para aceder à sua área pessoal.",
    [TC.PROPERTY_NOT_FOUND]: "Imóvel não encontrado",
    [TC.ACCOUNT_NOT_VERIFIED]: "Não foi possível verificar a sua conta",
    [TC.ACCOUNT_VALIDATED]: "Email verificado",
    [TC.ACCOUNT_VALIDATED_INFO_1]: "A sua conta de email foi verificada com sucesso.",
    [TC.ACCOUNT_VALIDATED_INFO_2]: "Pode agora aceder à sua área pessoal.",
    [TC.MESSAGE]: "Envie uma mensagem ao seu agente",
    [TC.SIGNUP_REQUEST_SENT]: "Pedido de registo enviado",
    [TC.RECOVER_REQUEST_SENT]: "Email de recuperação de senha enviado",
    [TC.RECOVER_PASSWORD_SENT_INFO_1]: "Foi enviado um email de recuperação de senha de acesso para a sua caixa de correio.",
    [TC.RECOVER_PASSWORD_SENT_INFO_2]: "Aceda à sua caixa de correio e siga as instruções fornecidas.",
    [TC.PROPERTIES]: "Imóveis",
    [TC.PROPERTY]: "Imóvel",
    [TC.BUSINESS_ACTIONS]: "Ações comerciais",
    [TC.PROPERTY_FEATURES]: "Características do imóvel",
    [TC.PUBLISH_WEBSITE]: "Publicado no website:",
    [TC.PUBLISH_PORTAL]: "Publicado em portais imobiliários:",
    [TC.CONTRACT_START_DATE]: "Data de início de contrato:",

    [TC.CLIENT_NAME]: "Interessado",
    [TC.PRIVATE_BUSINESSES]: "Negócios privados",
    [TC.ACTIVITIES_PROPERTY]: "Atividades relacionadas com o imóvel",
    [TC.ACTIVITY_PROPERTY]: "Atividades do imóvel",
    [TC.ACTIVITY_EMAIL]: "Contacto por Email",
    [TC.ACTIVITY_PHONE]: "Chamada telefónica",
    [TC.ACTIVITY_TASK]: "Tarefa",
    [TC.ACTIVITY_COMMITMENT]: "Compromisso",
    [TC.ACTIVITY_PROPOSAL]: "Proposta",
    [TC.ACTIVITY_VISIT]: "Visita",
    [TC.ACTIVITY_VIRTUAL_VISIT]: "Visita virtual",
    [TC.ACTIVITY_ALERT]: "Alerta",
    [TC.FEATURE_AREA]: "Área",
    [TC.FEATURE_NUMBER_OF_ROOMS]: "N.º de quartos",
    [TC.FEATURE_NUMBER_OF_BATHS]: "N.º de WC's",
    [TC.FEATURE_PRICE_PER_AREA]: "Preço por área",
    [TC.FEATURE_NUMBER_OF_GARAGES]: "Lugares de garagem",
    [TC.FEATURE_ENERGY_RATING]: "Certificado energético",
    [TC.FEATURE_DESCRIPTION]: "Descrição",
    [TC.FEATURE_VIEW_DESCRIPTION]: "Ver descrição",
    [TC.FEATURE_HIDE_DESCRIPTION]: "Esconder descrição",

    [TC.MISSING_USERNAME]: 'Introduza o seu email.',
    [TC.MISSING_PASSWORD]: 'Introduza a senha de acesso.',
    [TC.MISSING_CONFIRM_PASSWORD]: 'Confirme a nova senha de acesso.',
    [TC.MISSING_NEW_PASSWORD]: 'Introduza a nova senha de acesso.',
    [TC.MISSING_OLD_PASSWORD]: 'Introduza a senha de acesso atual.',
    [TC.MISSING_FULL_NAME]: 'Introduza o seu nome completo.',
    [TC.MISSING_ACTIVATION_CODE]: 'Insira o código de ativação.',
    [TC.MISSING_MESSAGE]: 'Escreva a mensagem que pretende enviar ao seu agente.',
    [TC.INVALID_EMAIL]: 'Introduza um email válido.',
    [TC.PASSWORD_MISMATCH]: 'As senhas de acesso são diferentes.',
    [TC.PASSWORD_INVALID]: 'A palavra-passe deverá ter entre 8 e 15 caracteres, pelo menos uma letra maiúscula e uma minúscula, pelo menos um algarismo e um caracter especial.',
    [TC.INVALID_REQUEST]: 'O pedido de validação efetuado contém dados incorretos.',
    [TC.EMAIL_NOT_SENT]: 'Não foi possível enviar o email',
    [TC.AUTH_REQUIRED]: 'É necessário assinalar a autorização de utilização dos seus dados pessoais para poder prosseguir.',
    [TC.UNKNOWN_ERROR]: 'Ocorreu um erro. Por favor tente novamente.',
    [TC.PASSWORD_UPDATED]: 'A senha de acesso foi atualizada com sucesso.',
    [TC.TIME_OUT]: 'Verifique a sua ligação à Internet.',
    [TC.MISSING_CREDENTIALS]: 'Dados de utilizador em falta.',
    [TC.SIGNUP_ACTIVATION_SUCCESS]: 'A sua conta foi ativada com sucesso.',
    [TC.RECOVER_PASSWORD_SUCCESS]: 'A senha de acesso foi alterada com sucesso.',
    [TC.WRONG_CREDENTIALS]: 'Usuário ou senha de acesso inválidos',
    [TC.NO_USER_INFO_AVAILABLE]: 'Usuário não encontrado.',
    [TC.SEE_MORE]: 'Ver visitas e atividades',
    [TC.SEE_LESS]: 'Esconder visitas e atividades',
    [TC.PROPERTY_LIST]: 'Os meus Imóveis',
    [TC.ACTIVITIES]: 'Atividades',
    [TC.TYPE]: 'Tipo',
    [TC.DESCRIPTION]: 'Descrição',
    [TC.REFERENCE]: 'Referência',
};

export default res_pt;
