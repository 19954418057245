import React from 'react';
import PropTypes from 'prop-types';
import defaultProfile from '../../images/profile.png';
import {removeWhiteSpaces, formatPhoneNumber} from "../../helpers/utils";
import {withNamespaces} from "react-i18next";
import {AGENT_TYPE} from "../../res/textConstants";
import { FaPhone, FaMobileAlt, FaEnvelope } from 'react-icons/fa';

const AgentExtended = (props) => {
    const {t} = props;
    const agent = props.agent;

    return (
        <div className="property-agent">
            <div className="agent-main">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="agent-image" style={{"backgroundImage": `url(${agent.image ? agent.image : defaultProfile})`}}></div>
                        <h4>{agent.name}</h4>
                        <h5>{t(AGENT_TYPE)}</h5>
                    </div>
                </div>
            </div>
            <div className="agent-contacts">
                <div className="row align-items-center">
                    <div className="col">
                        <ul>
                            {agent.phone && <li><FaPhone /> <a href={removeWhiteSpaces(agent.phone)}>{formatPhoneNumber(agent.phone)}</a></li>}
                            {agent.cell_phone && <li><FaMobileAlt /> <a href={removeWhiteSpaces(agent.cell_phone)}>{formatPhoneNumber(agent.cell_phone)}</a></li>}
                            {agent.email && <li><FaEnvelope /> <a href={`mailto:${agent.email}`}>{agent.email}</a></li>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

AgentExtended.propTypes = {
    agent: PropTypes.object.isRequired
};

export default withNamespaces('translations')(AgentExtended);
