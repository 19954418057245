import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {handleLogin} from "../actions/authedUser";
import {validateLoginForm} from '../helpers/validator';
import FormGroupInput from './dummy/forms/FormGroupInput';
import ButtonSubmit from "./dummy/forms/ButtonSubmit";
import {withNamespaces} from 'react-i18next';
import {LOGIN, USERNAME, PASSWORD, LOGIN_TERMS, FORGOT_MY_PASSWORD, LOGIN_GREETING_1, LOGIN_GREETING_2} from '../res/textConstants';

class Login extends Component {
    state = {
        authedUser: '',
        username: '',
        password: '',
        invalidField: ''
    };

    componentDidMount() {
        const {username} = this.props.match.params;

        if(username) {
            this.setState({
                username
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.authedUser.id && state.authedUser === '') {
            if(props.authedUser.permissions.own_properties) {
                props.history.push('/imoveis');
            } else {
                props.history.push('/negocios-privados');
            }

            return {
                ...state,
                authedUser: state.authedUser.id
            }
        }

        return state;
    }

    clearInvalidField = () => {
        this.setState(prev => ({
            invalidField: []
        }));
    };

    setInvalidField = (newInvalid) => {
        this.setState(prev => ({
            invalidField: newInvalid
        }));
    };

    checkIfFieldInvalid = (fieldName) => {
        return fieldName === this.state.invalidField
    };

    onChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prev => ({
            [name]: value
        }));
    };

    submit = (event) => {
        event.preventDefault();

        this.clearInvalidField();
        const validationResult = validateLoginForm(this.state.username, this.state.password);
        if(validationResult.valid) {
            this.props.dispatch(handleLogin(this.state.username, this.state.password));
        } else {
            this.setInvalidField(validationResult.invalidField);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div className="login">
                <div className="login-container">
                    <div className="left">
                        <h1>{t(LOGIN)}</h1>
                        <div className="subtitle">
                            <p>{t(LOGIN_GREETING_1)}</p>
                            <p>
                                {t(LOGIN_GREETING_2)}
                                <a href="https://www.improxy.pt/termos-de-uso/" rel="noopener noreferrer" target="_blank">{t(LOGIN_TERMS)}</a>
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <FormGroupInput
                                className='form-control'
                                label={t(USERNAME)}
                                type='email'
                                name='username'
                                invalidField={this.checkIfFieldInvalid('username')}
                                value={this.state.username}
                                onChange={this.onChange}
                                avoidAnimation={true}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(PASSWORD)}
                                type='password'
                                name='password'
                                invalidField={this.checkIfFieldInvalid('password')}
                                value={this.state.password}
                                onChange={this.onChange}
                                avoidAnimation={true}/>

                            <ButtonSubmit
                                className='m-button'
                                label={t(LOGIN)}
                                loading={this.props.loading}
                                onClick={this.submit}/>
                        </form>
                        <div className="forgot-password">
                            <p><Link to='/senha'>{t(FORGOT_MY_PASSWORD)}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({loading, authedUser}, props) {
    return {
        loading: loading.button > 0,
        authedUser,
        ...props
    }
}

export default connect(mapStateToProps)(withNamespaces('translations')(Login));
