import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {LANGUAGE_WHITELIST} from "../config/config";
import res_pt from './res_pt';
import res_br from './res_br';
import res_en from './res_en';

i18n.use(LanguageDetector).init({
    fallbackLng: 'pt',
    whitelist: LANGUAGE_WHITELIST,
    ns: ["translations"],
    defaultNS: "translations",
    resources: {
        'pt-PT': {
            translations: res_pt
        },
        'pt-BR': {
            translations: res_br
        },
        'pt': {
            translations: res_pt
        },
        'en': {
            translations: res_en
        }
    }
});

export default i18n;
