import React from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";
import {DATE, TYPE, CLIENT_NAME, ACTIVITY_PROPERTY} from '../../res/textConstants';
import BootstrapTable from 'react-bootstrap-table-next';
import {ACTIVITY_TYPE} from "../../config/config";

const ActivityRow = (props) => {
    const {t, activitiesList, showTitle} = props;

    activitiesList.map(activity => {
        activity.activity_type = t(ACTIVITY_TYPE[activity.activity_type_id]);
        return activity;
    });

    const columns = [
        {
            dataField: 'insert_date',
            text: `${t(DATE)}`,
            sort: false,
            align: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '24%'};
            }
        },
        {
            dataField: 'activity_type',
            text: `${t(TYPE)}`,
            sort: false,
            align: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '38%'};
            }
        },
        {
            dataField: 'client_name',
            text: `${t(CLIENT_NAME)}`,
            sort: false,
            align: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '38%'};
            }
        },
    ];

    const defaultSorted = [{
        dataField: 'insert_date',
        order: 'desc'
    }];

    return (
        <div className="card-property">
            <h3 style={{opacity: showTitle ? 1 : 0}}>{t(ACTIVITY_PROPERTY)}</h3>

            <BootstrapTable
                bootstrap4
                keyField='id'
                data={activitiesList}
                columns={columns}
                defaultSorted={defaultSorted}
                rowClasses='table-visit-row'
            >
            </BootstrapTable>
        </div>
    );
};

ActivityRow.propTypes = {
    activitiesList: PropTypes.array.isRequired
};

export default withNamespaces('translations')(ActivityRow);
