import React from 'react';
import PropTypes from 'prop-types';
import {FaGlobeAfrica} from "react-icons/fa";

const Footer = (props) => {
    const {language, onLanguageChange, company} = props;

    return (
        <footer>
            <div className="footer-container agency-contacts">
                <div className="left">
                    <span>{company.subsidiary_address}</span><br/>
                    <span>{company.subsidiary_address_zipcode}</span>
                    <span>{company.subsidiary_address_ziplocation}</span>
                </div>
                <div className="right">
                    <span>{company.subsidiary_phone}</span><br/>
                    <span>{company.subsidiary_cell_phone}</span><br/>
                    <span>{company.portal_email}</span>
                </div>
            </div>
            <div className="footer-container copyright">
                <div className="left">
                    <span className='language-selector'>
                        <FaGlobeAfrica/>
                    </span>
                    <select name='language' value={language} onChange={onLanguageChange}>
                        {props.languagesAvailable.map(lng => (
                            <option key={lng.language} value={lng.value}>
                                {lng.language}
                            </option>
                        ))}
                    </select>
                    <span>{`© ${new Date().getFullYear()} ${company.fullname}`}</span>
                </div>
                <div className="right">
                    <div className="footer-powered">
                        <span>Powered by: <a href='https://improxy.pt' target='_blank' rel="noopener noreferrer">Improxy</a></span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    company: PropTypes.object.isRequired,
    languagesAvailable: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    onLanguageChange: PropTypes.func.isRequired
};

export default Footer;
