import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    USERNAME,
    SIGNUP,
    LOGIN,
    AUTH_GENERIC,
    AUTH_SIGNUP,
    ALREADY_HAVE_ACCOUNT,
    FULL_NAME,
    SIGNUP_GREETING_1,
    SIGNUP_GREETING_2,
    LOGIN_TERMS
} from '../res/textConstants';
import FormGroupInput from "./dummy/forms/FormGroupInput";
import ButtonSubmit from "./dummy/forms/ButtonSubmit";
import {validateSignupForm} from "../helpers/validator";
import { signup } from "../helpers/API";
import alertDispatcher from '../helpers/alertDispatcher';
import { ALERT_MESSAGE_TYPE } from "../helpers/constants";
import {Link} from "react-router-dom";
import Auth from './dummy/forms/Auth';
import {startButtonLoading, endButtonLoading} from "../actions/loading";
import {checkRecaptcha} from "../helpers/utils";
import {withNamespaces} from "react-i18next";

class Signup extends Component {
    state = {
        fullname: '',
        username: '',
        auth: false,
        invalidField: ''
    };

    clearInvalidField = () => {
        this.setState(prev => ({
            invalidField: []
        }));
    };

    setInvalidField = (newInvalid) => {
        this.setState(prev => ({
            invalidField: newInvalid
        }));
    };

    checkIfFieldInvalid = (fieldName) => {
        return fieldName === this.state.invalidField
    };

    onChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prev => ({
            [name]: value
        }));
    };

    onCheckboxChange = (event) => {
        const { name, checked } = event.target;

        this.setState(prev => ({
            [name]: checked
        }));
    };

    submit = (event) => {
        event.preventDefault();

        this.clearInvalidField();
        const validationResult = validateSignupForm(this.state.fullname, this.state.username, this.state.auth);

        if(validationResult.valid) {
            // Direct call to API.
            // No data will be stored in redux store.
            this.props.dispatch(startButtonLoading());
            checkRecaptcha('recoverPassword')
                .then(token => {
                    signup(this.state.fullname, this.state.username, this.state.auth, token)
                    .then(data => {
                        this.props.history.push('/registo/sucesso');
                        this.props.dispatch(endButtonLoading());
                    })
                    .catch(error => {
                        alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
                        this.props.dispatch(endButtonLoading());
                    });
                });
        } else {
            this.setInvalidField(validationResult.invalidField);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div className="login">
                <div className="login-container">
                    <div className="left">
                        <h1>{t(SIGNUP)}</h1>
                        <div className="subtitle">
                            <p>{t(SIGNUP_GREETING_1)}</p>
                            <p>
                                {t(SIGNUP_GREETING_2)}
                                <a href="https://www.improxy.pt/termos-de-uso/" rel="noopener noreferrer" target="_blank">{t(LOGIN_TERMS)}</a>
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <FormGroupInput
                                className='form-control'
                                label={t(FULL_NAME)}
                                type='text'
                                name='fullname'
                                invalidField={this.checkIfFieldInvalid('fullname')}
                                value={this.state.fullname}
                                onChange={this.onChange}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(USERNAME)}
                                type='email'
                                name='username'
                                invalidField={this.checkIfFieldInvalid('username')}
                                value={this.state.username}
                                onChange={this.onChange} />

                            <Auth
                                label={[t(AUTH_SIGNUP), " ", <strong key='strong-key'>{t(AUTH_GENERIC)}</strong>]}
                                checked={this.state.auth}
                                onChange={this.onCheckboxChange}/>

                            <ButtonSubmit
                                className='m-button'
                                label={t(SIGNUP)}
                                loading={this.props.loading}
                                onClick={this.submit}/>

                        </form>
                        <div className="already-have-account">
                            <p>{t(ALREADY_HAVE_ACCOUNT)} <Link to='/'>{t(LOGIN)}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({loading}) {
    return {
        loading: loading.button > 0
    };
}

export default connect(mapStateToProps)(withNamespaces('translations')(Signup));
