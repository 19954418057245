import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import PropertyInfo from './PropertyInfo';
import {withNamespaces} from "react-i18next";
import 'rc-collapse/assets/index.css';

const PropertyRow = (props) => {
    const {
        property,
    } = props;

    const {} = property;

    return (
        <Fragment>
            <div className="property-list-row">
                <div className="property-item-content">
                    <div className="property-item-content-wrapper">
                        <PropertyInfo
                            property={property}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

PropertyRow.propTypes = {
    property: PropTypes.object.isRequired
};

export default withNamespaces('translations')(PropertyRow);
