import React from 'react';
import PropTypes from 'prop-types';

const ButtonSubmit = (props) => {

    // TODO: HA UM PROP QUE ASSINALA SE UM LOADING ESTA A DECORRER PARA SE DESATIVAR O BOTAO
    // props.loading = true;

    return(
        <button
            className={props.className}
            disabled={props.loading}
            onClick={props.onClick}>
            {props.label}
        </button>
    );
};

ButtonSubmit.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default ButtonSubmit;