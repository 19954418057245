import * as API from '../helpers/API';
import {startContentLoading, endContentLoading} from "./loading";
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {composePropertyLocation, composePropertyTitle, formatDate} from "../helpers/utils"; 

export const SET_PROPERTIES_USER = "SET_PROPERTIES_USER";
export const CLEAR_PROPERTIES_USER = "CLEAR_PROPERTIES_USER";

function setUserProperties(properties) {
    return {
        type: SET_PROPERTIES_USER,
        properties
    };
}

export function handleSetUserProperties(parameters) {
    return (dispatch) => {
        dispatch(startContentLoading());
        API.getUserProperties(parameters)
            .then(data => {
                const properties = data.Properties;
                const agents = data.Properties_agents;
                const visits = data.Properties_visits;
                const gallery = data.Properties_gallery;
                const activities = data.Activities;

                visits.map((visit, index) => {
                    visit.start_date = formatDate(visit.start_date);
                    visit.id = index;
                    return visit;
                });

                activities.map((activity, index) => {
                    activity.insert_date = formatDate(activity.insert_date);
                    activity.id = index;
                    return activity;
                });

                properties.map(property => {
                    property.gallery = gallery.filter(photo => photo.property_id === property.id);
                    property.agents = agents.filter(agent => agent.property_id === property.id);
                    property.visits = visits.filter(visit => visit.property_id === property.id);
                    property.activities = activities.filter(activity => activity.property_id === property.id);
                    property.location = composePropertyLocation(property.location_level_1, property.location_level_2, property.location_level_3, property.location_level_4);
                    property.completeLocation = property.location_level_1 + ", " + property.location_level_2  + ", " + property.location_level_3 + ", " + property.location_level_4;
                    property.title = composePropertyTitle(property.type, property.bedrooms);
                    delete property.location_level_1;
                    delete property.location_level_2;
                    delete property.location_level_3;
                    delete property.location_level_4;
                    return property;
                });

                dispatch(setUserProperties(properties));
                dispatch(endContentLoading());
            })
            .catch(error => {
                dispatch(endContentLoading());
                alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
            });
    };
}

export function clearUserProperties() {
    return {
        type: CLEAR_PROPERTIES_USER
    }
}
