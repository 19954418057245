import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Route, withRouter, Switch} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {handleCheckAuthedUser} from "../actions/authedUser";
import {handleSetCompanyInfo} from "../actions/company";
import {setWidth} from "../actions/parameters";
import Header from './Header';
import Loading from './dummy/Loading';
import Login from './Login';
import Signup from './Signup';
import SignupSuccess from './SignupSuccess';
import PasswordSet from './PasswordSet';
import ForgotPassword from './ForgotPassword';
import PropertyList from './PropertyList';
import UserProfile from './UserProfile';
import RecoverPasswordSent from './RecoverPasswordSent';
import Footer from './dummy/Footer';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import {LOGOUT_ONLY_ROUTES} from "../config/config";
import {loadRecaptcha, getAvailableLanguages} from "../helpers/utils";
import i18n from '../res/i18n';

import PropertyDashboard from './PropertyDashboard';


class App extends Component {
    state = {
        started: false,
        ready: false,
        recaptcha: {
            ready: false,
            token: null
        },
        language: ''
    };

    componentDidMount() {

        loadRecaptcha(window.imp.RECAPTCHA_KEY);

        this.props.dispatch(handleSetCompanyInfo());
        this.props.dispatch(handleCheckAuthedUser());

        this.setState({
            language: i18n.language
        });

        window.addEventListener('resize', this.setWidth);

        this.setWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidth);
    }

    setWidth = () => {
        this.props.dispatch(setWidth(window.innerWidth));
    };

    static getDerivedStateFromProps(props, state) {
        if(!state.started && props.loading.initial > 0) {
            return {
                ...state,
                started: true
            };
        } else if (state.started && !state.ready && !props.loading.initial) {
            const routeSplit = props.location.pathname.split('/');
            let mainRoute = '/';
            if(routeSplit.length > 1) {
                mainRoute = `/${routeSplit[1]}`;
            }

            const isOnLogoutOnlyArea = LOGOUT_ONLY_ROUTES.indexOf(mainRoute) !== -1;

            if (props.authedUser.id && isOnLogoutOnlyArea) {
                props.history.push("/imoveis");
            }

            return {
                ...state,
                ready: true
            };
        }

        return state;
    }

    componentDidUpdate(prevProps, prevState) {
        if(!localStorage.getItem("user-set-language-preference") && this.props.company.country) {

            const newLanguage = getAvailableLanguages(this.props.company.country)[0].value;
            localStorage.setItem("user-set-language-preference", "1");
            this.changeLanguage(newLanguage);
        }
    }

    changeLanguage = (language) => {
        if(language !== this.state.language) {
            this.setState({
                language
            });

            i18n.changeLanguage(language);
        }
    };


    onLanguageChange = (event) => {
        const language = event.target.value;

        if(language !== this.state.language) {
            this.setState({
                language
            });

            i18n.changeLanguage(language);
        }
    };

    render() {
        if (!this.state.ready) {
            return (
                <Fragment>
                    <Loading />
                    <Alert stack={{limit: 3}}/>
                </Fragment>
            )
        }

        const company = this.props.company;

        return (
            <Fragment>
                <Header/>
                <TransitionGroup className="root-wrapper">
                    <CSSTransition
                        key={this.props.location.key}
                        classNames='fade'
                        timeout={300}
                        appear>
                        <Switch location={this.props.location}>
                            <Route exact path='/' component={Login}/>
                            <Route exact path='/login' component={Login}/>
                            <Route exact path='/login/:username' component={Login}/>
                            <Route exact path='/registo' component={Signup}/>
                            <Route exact path='/registo/sucesso' component={SignupSuccess}/>
                            <Route exact path='/registo/validacao/:username/:token' render={(props) => <PasswordSet {...props} type="signup" />}/>
                            <Route exact path='/senha' component={ForgotPassword}/>
                            <Route exact path='/senha/envio' component={RecoverPasswordSent}/>
                            <Route exact path='/senha/recuperar/:username/:token' render={(props) => <PasswordSet {...props} type="recover" />}/>
                            <Route path='/imoveis' component={PropertyList} />
                            <Route path='/negocios-privados' render={(props) => <PropertyList {...props} type='PRIVATE_BUSINESSES' />} />
                            <Route path='/perfil' component={UserProfile}/>
                            
                            <Route path='/imoveisfilter' component={PropertyDashboard} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
                <Footer
                    company={company}
                    language={this.state.language}
                    languagesAvailable={getAvailableLanguages(company.country)}
                    onLanguageChange={(event) => {
                        this.changeLanguage(event.target.value);
                    }}/>
                <Alert stack={{limit: 3}}/>
            </Fragment>
        );
    }
}

function mapStateToProps({loading, authedUser, company}, props) {
    return {
        loading,
        authedUser,
        company,
        ...props
    };
}

export default withRouter(connect(mapStateToProps)(App));
