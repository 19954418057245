import * as TC from '../res/textConstants';
import i18n from '../res/i18n';
import {LANGUAGES_AVAILABLE, COUNTRIES, COUNTRY_LANGUAGES, STYLE_BREAKPOINTS} from "../config/config";

export function getFirstName(name) {
    if (!name) {
        return '';
    }

    return name.split(' ')[0];
}

export function composePropertyTitle(type, typology) {
    let result = type;

    if (typology) {
        const BEDROOM_TEXT = typology === 1 ? TC.BEDROOM : TC.BEDROOMS;
        result = `${result} ${typology} ${getTranslatedText(BEDROOM_TEXT)}`;
    }
    return result;
}

function placeCommaAtEnd(expression) {
    if (expression) {
        return `${expression},`;
    }

    return '';
}

export function composePropertyLocation(country, district, county, parish) {
    district = placeCommaAtEnd(district);
    county = placeCommaAtEnd(county);
    parish = placeCommaAtEnd(parish);

    country = country.toLowerCase();

    let result;
    switch(country) {
        case 'cabo verde':
        case 'angola':
        case 'moçambique':
            result = `${parish} ${county} ${district}`;
            break;

        default:
            result = `${parish} ${county}`;
            break;
    }


    return result.substring(0, result.length - 1);
}

export function removeWhiteSpaces(expression) {
    return expression.replace(/ /g, '');
}

export function formatPrice(price, currency = '€') {
    if(!price) {
        return '';
    }

    let intPart = parseInt(price).toString();
    const aux = price
        .toString()
        .replace(',', '.')
        .split('.');
    const decPart = aux.length > 1 ? aux[1] : '';

    intPart = spaceExpression(intPart);

    if(decPart) {
        return `${intPart}.${decPart} ${currency}`;
    }
    return `${intPart} ${currency}`;
}

export function spaceExpression(expression, spaceInterval=3) {
    if(!expression) {
        return '';
    }

    expression = removeWhiteSpaces(expression)
        .split('')
        .reverse('');

    let result = '';
    for (const i in expression) {
        if (i !== 0 && i % spaceInterval === 0) {
            result += ' ';
        }
        result += expression[i];
    }

    return result
        .split('')
        .reverse()
        .join('')
        .trim();
}

export function formatPhoneNumber(expression) {
    if (!expression) {
        return '';
    }

    return spaceExpression(expression);
}

export function formatDate(dateStr) {
    const aux = new Date(dateStr);
    const year = aux.getFullYear();
    let month = aux.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;

    let day = aux.getDate();
    day = day < 10 ? `0${day}` : `${day}`;


    return `${day}-${month}-${year}`;
}

export function loadRecaptcha() {
    const url = `https://www.google.com/recaptcha/api.js?render=${window.imp.RECAPTCHA_KEY}`;
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
}

export function checkRecaptcha(actionName) {
    return window.grecaptcha.execute(window.imp.RECAPTCHA_KEY, {action: actionName});
}

export function pickErrorMessage(serverMessage = "") {
    const messageKey = serverMessage.toUpperCase();
    let message = TC[messageKey];
    if (!message) {
        message = 'UNKNOWN_ERROR';
    }

    return message;
}

export function getTranslatedText(resourceKey) {
    return i18n.getResource(i18n.language, "translations", resourceKey);
}

export function getAvailableLanguages(country) {
    const countryCode = COUNTRIES[country];

    if(!country || !countryCode) {
        return LANGUAGES_AVAILABLE;
    }

    return COUNTRY_LANGUAGES[countryCode];
}

export function getCurrentBreakPoint (width) {
    return [...STYLE_BREAKPOINTS]
        .reverse()
        .find(bk => {
            return width >= bk;
        });
}
