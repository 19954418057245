import {SET_COMPANY_INFO} from "../actions/company";

export default function company(state = {}, action) {
    switch(action.type) {
        case SET_COMPANY_INFO:
            return {
                ...action.info
            };

        default:
            return state;
    }
}