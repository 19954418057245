import { HANDLERS } from "../config/config";
import { REQUEST_TIMEOUT } from "../config/config";
import axios from "axios";
import { pickErrorMessage } from "./utils";

const postRequest = (url, data, method = "post") => {
  return axios({
    method: method,
    url,
    timeout: REQUEST_TIMEOUT,
    withCredentials: true,
    responseType: "json",
    responseEncoding: "utf8",
    data
  });
};

const promiseFactory = (handler, data = {}, portalToken) => {
  data.portal_token = portalToken || window.imp.PORTAL_TOKEN;

  return new Promise((res, rej) => {
    postRequest(handler, JSON.stringify(data))
      .then(result => {
        result = result.data;
        if (result.Exception.ClassName) {
          rej(pickErrorMessage(result.Exception.Message));
          return;
        }

        if (!result.Response.Data) {
          rej(pickErrorMessage());
          return;
        }

        res(result.Response.Data);
      })
      .catch(e => {
        console.log("->", handler, e);
        rej(pickErrorMessage());
      });
  });
};

export const getCompanyInfo = (data, portal_token) => {
  return promiseFactory(HANDLERS.COMPANY_INFO, data, portal_token);
};

export const checkIfUserLoggedIn = () => {
  return promiseFactory(HANDLERS.USER);
};

export const login = (username, password) => {
  const data = {
    username,
    password
  };

  return promiseFactory(HANDLERS.LOGIN, data);
};

export const signup = (fullname, username, auth, token) => {
  const data = {
    fullname,
    username,
    auth,
    token
  };

  return promiseFactory(HANDLERS.SIGNUP, data);
};

export const forgotPassword = (username, token) => {
  const data = {
    username,
    token
  };

  return promiseFactory(HANDLERS.FORGOT_PASSWORD, data);
};

export const accountActivation = (
  username,
  password,
  userToken,
  recaptchaToken
) => {
  const data = {
    username,
    password,
    userToken: encodeURIComponent(userToken),
    token: recaptchaToken
  };

  return promiseFactory(HANDLERS.ACCOUNT_ACTIVATION, data);
};

export const recoverPassword = (
  username,
  password,
  userToken,
  recaptchaToken
) => {
  const data = {
    username,
    password,
    userToken: encodeURIComponent(userToken),
    token: recaptchaToken
  };

  return promiseFactory(HANDLERS.RECOVER_PASSWORD, data);
};

export const updatePassword = (username, oldPassword, newPassword) => {
  const data = {
    username,
    oldPassword,
    newPassword
  };

  return promiseFactory(HANDLERS.UPDATE_PASSWORD, data);
};

export function logout() {
  return promiseFactory(HANDLERS.LOGOUT);
}

export function getUserProperties(parameters) {
  // Function to retrieve own properties.
  return promiseFactory(HANDLERS.PROPERTIES_USER, parameters);
}

export function getAgencyProperties(parameters) {
  return promiseFactory(HANDLERS.PROPERTIES_AGENCY, parameters);
}

export function sendMessage(message, propertyId, token) {
  const data = {
    message,
    propertyId,
    token
  };

  return promiseFactory(HANDLERS.SEND_MESSAGE, data);
}

export function getJsonProperties(country) {
  const data = {};
  const jsonURL = "https://cdn.gimob.net/json/" + country + ".json";
  return propertyFactory(jsonURL, data);
}

const propertyFactory = (handler, data) => {
  return new Promise((res, rej) => {
    axios
      .get(handler)
      .then(result => {
        if (!result.data) {
          rej(pickErrorMessage());
          return;
        }
        res(result.data);
      })
      .catch(e => {
        console.log("->", handler, e);
        rej(pickErrorMessage());
      });
  });
};
