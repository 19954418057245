import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import Loading from "./dummy/Loading";
import {connect} from 'react-redux';
import FormGroupInput from './dummy/forms/FormGroupInput';
import ButtonSubmit from './dummy/forms/ButtonSubmit';
import {UPDATE_PASSWORD, OLD_PASSWORD, NEW_PASSWORD, CONFIRM_NEW_PASSWORD, SEND} from "../res/textConstants";
import {validateUpdatePasswordForm} from "../helpers/validator";
import {updatePassword} from '../helpers/API';
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {startButtonLoading, endButtonLoading} from "../actions/loading";
import {PASSWORD_UPDATED} from "../res/textConstants";
import {checkRecaptcha} from "../helpers/utils";
import {withNamespaces} from "react-i18next";

class UserProfile extends Component {
    uiRemoved = false;

    state = {
        forceLogin: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        invalidField: ''
    };

    componentDidMount() {
        if (!this.props.authedUser.id) {
            this.setState(prev => ({
                forceLogin: true
            }));
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.authedUser.id) {
            return {
                ...state,
                forceLogin: true
            }
        }

        return state;
    }

    clearInvalidField = () => {
        this.setState(prev => ({
            invalidField: []
        }));
    };

    setInvalidField = (newInvalid) => {
        this.setState(prev => ({
            invalidField: newInvalid
        }));
    };

    checkIfFieldInvalid = (fieldName) => {
        return fieldName === this.state.invalidField
    };

    onChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState(prev => ({
            [name]: value
        }));
    };

    submit = (event) => {
        event.preventDefault();

        this.clearInvalidField();

        const validationResult = validateUpdatePasswordForm(this.state.oldPassword, this.state.newPassword, this.state.confirmPassword);
        if (validationResult.valid) {
            this.props.dispatch(startButtonLoading());
            checkRecaptcha('recoverPassword')
                .then(token => {
                    updatePassword(this.props.authedUser.email, this.state.oldPassword, this.state.newPassword)
                    .then(data => {
                        alertDispatcher(ALERT_MESSAGE_TYPE.SUCCESS, PASSWORD_UPDATED);
                        this.props.dispatch(endButtonLoading());
                    })
                    .catch(error => {
                        alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
                        this.props.dispatch(endButtonLoading());
                    });
                });
        } else {
            this.setInvalidField(validationResult.invalidField);
        }
    };

    render() {
        if (this.state.forceLogin && !this.uiRemoved) {
            this.uiRemoved = true;
            return (
                <Redirect to='/'/>
            );
        }

        if (this.props.loading.content > 0) {
            return (
                <Loading />
            );
        }

        const {t} = this.props;

        return (
            <div className="login">
                <div className="login-container user-profile">
                    <div className="left one">
                        <h1>{t(UPDATE_PASSWORD)}</h1>
                        <form>
                            <FormGroupInput
                                className='form-control'
                                label={t(OLD_PASSWORD)}
                                type='password'
                                name='oldPassword'
                                value={this.state.oldPassword}
                                invalidField={this.checkIfFieldInvalid('oldPassword')}
                                onChange={this.onChange}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(NEW_PASSWORD)}
                                type='password'
                                name='newPassword'
                                value={this.state.newPassword}
                                invalidField={this.checkIfFieldInvalid('newPassword')}
                                onChange={this.onChange}/>

                            <FormGroupInput
                                className='form-control'
                                label={t(CONFIRM_NEW_PASSWORD)}
                                type='password'
                                name='confirmPassword'
                                value={this.state.confirmPassword}
                                invalidField={this.checkIfFieldInvalid('confirmPassword')}
                                onChange={this.onChange}/>

                            <ButtonSubmit
                                className='m-button'
                                label={t(SEND)}
                                loading={this.props.loading}
                                onClick={this.submit}/>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({loading, authedUser}, props) {
    return {
        loading: loading.buttons > 0,
        authedUser,
        ...props
    }
}

export default connect(mapStateToProps)(withNamespaces('translations')(UserProfile));
