import * as API from '../helpers/API';
import {endInitialLoading, startInitialLoading} from "./loading";
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {UNKNOWN_ERROR} from "../res/textConstants";

export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';

function setCompanyInfo(info) {
    return {
        type: SET_COMPANY_INFO,
        info
    }
}

export function handleSetCompanyInfo() {
    return (dispatch) => {
        dispatch(startInitialLoading());
        API.getCompanyInfo()
        .then(data => {
            if(!data.name || !data.fullname || !data.logo) {
                alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, UNKNOWN_ERROR, 60000);
                return;
            }

            dispatch(setCompanyInfo(data));
            dispatch(endInitialLoading());
        })
        .catch(error => {
            alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error, 60000);
        });
    };
}

