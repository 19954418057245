import {REMOVE_AUTHED_USER, SET_AUTHED_USER} from "../actions/authedUser";

export default function authedUser(state = {}, action) {
    switch(action.type) {
        case SET_AUTHED_USER:
            return {
                ...action.authedUser,
                name: action.authedUser.DisplayName,
                id: action.authedUser.UserId
            };

        case REMOVE_AUTHED_USER:
            return {};

        default:
            return state;
    }
}
