import * as TC from './textConstants';

const res_en = {
    [TC.LOGIN]: 'Login',
    [TC.USERNAME]: 'Email',
    [TC.PASSWORD]: 'Password',
    [TC.CONFIRM_PASSWORD]: 'Confirm your password',
    [TC.FULL_NAME]: 'Full name',
    [TC.SIGNUP]: 'Sign up',
    [TC.SIGNUP_NOW]: 'Sign up',
    [TC.LOGIN_TERMS]: 'terms and conditions.',
    [TC.LOGIN_GREETING_1]: 'Welcome to Gimob Online!',
    [TC.LOGIN_GREETING_2]: 'By logging in you agree to the ',
    [TC.SIGNUP_GREETING_1]: 'Welcome to Gimob Online!',
    [TC.SIGNUP_GREETING_2]: 'By signing up for this application you agree to the ',
    [TC.RECOVER_GREETING_1]: 'Welcome to Gimob Online!',
    [TC.RECOVER_GREETING_2]: 'By recover password for this application you agree to the ',
    [TC.LOADING]: 'Loading...',
    [TC.BACK_HOME]: 'Back to home page',
    [TC.BACK]: 'Back',
    [TC.SEND]: 'Send',
    [TC.ACTIVATE]: 'Activate',
    [TC.ACTIVATION_CODE]: 'Activation code',
    [TC.LOGOUT]: 'Logout',
    [TC.HELLO]: 'Hello',
    [TC.AGENT_TYPE]: 'Agent',
    [TC.AGENT]: 'property agent',
    [TC.AGENTS]: 'property agents',
    [TC.CONTACT_PHONE]: 'Telephone',
    [TC.CONTACT_CELL_PHONE]: 'Cell phone',
    [TC.CONTACT_EMAIL]: 'Email',
    [TC.NO_ACCOUNT]: "Don't have an account?",
    [TC.ALREADY_HAVE_ACCOUNT]: 'Already have an account?',
    [TC.FORGOT_MY_PASSWORD]: 'Forgot my password',
    [TC.RECOVER_PASSWORD]: 'Recover password',
    [TC.UPDATE_PASSWORD]: 'Update password',
    [TC.SIGNUP_ACTIVATION]: 'Signup activation',
    [TC.DATE]: 'Date',
    [TC.CONDITION]: 'Condition',
    [TC.VISIT_PROPERTY]: 'Visits to the property',
    [TC.VISIT_DATE]: 'Visit date',
    [TC.VISIT_INTEREST]: 'Interest',
    [TC.VISIT_PRICE]: 'Price',
    [TC.VISIT_LOCATION]: 'Location',
    [TC.VISIT_CONDITION]: 'property condition',
    [TC.VISIT_POSITIVE]: 'Positive aspects',
    [TC.VISIT_NEGATIVE]: 'Negative aspects',
    [TC.VISIT_VIRTUAL]: 'Sent by email:',
    [TC.WEBSITE_VIEWS]: 'Website views',
    [TC.BEDROOM]: 'bedroom',
    [TC.BEDROOMS]: 'bedrooms',
    [TC.NO_RESULTS]: 'No properties where found.',
    [TC.OLD_PASSWORD]: 'Current password',
    [TC.NEW_PASSWORD]: 'New password',
    [TC.CONFIRM_PASSWORD]: 'Confirm password',
    [TC.CONFIRM_NEW_PASSWORD]: 'Confirm new password',
    [TC.AUTH_FORGOT_PASSWORD]: 'I declare that I am the holder of these personal data and authorize the use of them for the purpose of retrieving my password.',
    [TC.AUTH_SIGNUP]: 'I declare that I am the holder of these personal data and authorize the passage of the same in order to gain access to the customer area.',
    [TC.AUTH_GENERIC]: '(In order to receive your request, you must check this option)',
    [TC.VALIDATION_NEEDED]: "Validation needed",
    [TC.VALIDATION_NEEDED_INFO_1]: "Your signup request was sent to the agency",
    [TC.VALIDATION_NEEDED_INFO_2]: "In the next few days, you'll receive an email with further instructions to access your personal area.",
    [TC.PROPERTY_NOT_FOUND]: "property not found",
    [TC.ACCOUNT_NOT_VERIFIED]: "We couldn't verify your account",
    [TC.ACCOUNT_VALIDATED]: "Email checked",
    [TC.ACCOUNT_VALIDATED_INFO_1]: "Email verification completed.",
    [TC.ACCOUNT_VALIDATED_INFO_2]: "Please login to access your personal area.",
    [TC.MESSAGE]: "Send a message to your agent",
    [TC.SIGNUP_REQUEST_SENT]: "Signup request sent",
    [TC.RECOVER_REQUEST_SENT]: "Password recovery email sent",
    [TC.RECOVER_PASSWORD_SENT_INFO_1]: "An email was sent to your inbox.",
    [TC.RECOVER_PASSWORD_SENT_INFO_2]: "Please follow the email instructions to recover your password.",
    [TC.PROPERTIES]: "Properties",
    [TC.PROPERTY]: "property",
    [TC.BUSINESS_ACTIONS]: "Business actions",
    [TC.PROPERTY_FEATURES]: "Property features",
    [TC.PUBLISH_WEBSITE]: "Published on website:",
    [TC.PUBLISH_PORTAL]: "Posted in Real Estate Portals:",
    [TC.CONTRACT_START_DATE]: "Contract start date:",

    [TC.CLIENT_NAME]: "Visitor",
    [TC.PRIVATE_BUSINESSES]: "Private businesses",
    [TC.ACTIVITIES_PROPERTY]: "Activities related with the property",
    [TC.ACTIVITY_PROPERTY]: "property activities",
    [TC.ACTIVITY_EMAIL]: "Email contact",
    [TC.ACTIVITY_PHONE]: "Phone contact",
    [TC.ACTIVITY_TASK]: "Task",
    [TC.ACTIVITY_COMMITMENT]: "Commitment",
    [TC.ACTIVITY_PROPOSAL]: "Proposal",
    [TC.ACTIVITY_VISIT]: "Visit",
    [TC.ACTIVITY_VIRTUAL_VISIT]: "Virtual visit",
    [TC.ACTIVITY_ALERT]: "Alert",
    [TC.FEATURE_AREA]: "Area",
    [TC.FEATURE_NUMBER_OF_ROOMS]: "N.º of bedrooms",
    [TC.FEATURE_NUMBER_OF_BATHS]: "N.º de bathrooms",
    [TC.FEATURE_PRICE_PER_AREA]: "Price per area",
    [TC.FEATURE_NUMBER_OF_GARAGES]: "Car parking spaces",
    [TC.FEATURE_ENERGY_RATING]: "Energy rating",
    [TC.FEATURE_DESCRIPTION]: "Description",
    [TC.FEATURE_VIEW_DESCRIPTION]: "View description",
    [TC.FEATURE_HIDE_DESCRIPTION]: "Hide description",

    [TC.MISSING_USERNAME]: 'Insert your email.',
    [TC.MISSING_PASSWORD]: 'Insert your password.',
    [TC.MISSING_CONFIRM_PASSWORD]: 'Confirm the new password.',
    [TC.MISSING_NEW_PASSWORD]: 'Insert your new password.',
    [TC.MISSING_OLD_PASSWORD]: 'Insert your current password.',
    [TC.MISSING_FULL_NAME]: 'Insert your full name.',
    [TC.MISSING_ACTIVATION_CODE]: 'Insert validation code.',
    [TC.MISSING_MESSAGE]: 'Write a message for your agent.',
    [TC.INVALID_EMAIL]: 'Insert a valid email.',
    [TC.PASSWORD_MISMATCH]: "Passwords don't match.",
    [TC.PASSWORD_INVALID]: 'The password must be between 8 and 15 characters, at least one upper and lower case, at least one number and a special character.',
    [TC.INVALID_REQUEST]: 'The validation request contains incorrect data.',
    [TC.EMAIL_NOT_SENT]: "Could not send email",
    [TC.AUTH_REQUIRED]: 'It is necessary to check the authorization to use your personal data in order to proceed.',
    [TC.UNKNOWN_ERROR]: 'An error occurred. Please try again.',
    [TC.PASSWORD_UPDATED]: 'Password successfully updated.',
    [TC.TIME_OUT]: 'Check your Internet connection.',
    [TC.MISSING_CREDENTIALS]: 'Missing user data.',
    [TC.SIGNUP_ACTIVATION_SUCCESS]: 'Account successfully activated.',
    [TC.RECOVER_PASSWORD_SUCCESS]: 'Password successfully updated.',
    [TC.WRONG_CREDENTIALS]: 'Wrong username or password.',
    [TC.NO_USER_INFO_AVAILABLE]: 'User not found.',
    [TC.SEE_MORE]: 'See visits and activities',
    [TC.SEE_LESS]: 'Hide visits and activities',
    [TC.PROPERTY_LIST]: 'My Properties',
    [TC.ACTIVITIES]: 'Activities',
    [TC.TYPE]: 'Type',
    [TC.DESCRIPTION]: 'Description',
    [TC.REFERENCE]: 'Reference',
};

export default res_en;
