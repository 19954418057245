import React from 'react';
import PropTypes from 'prop-types';
import { FaHouseDamage } from 'react-icons/fa';

const NoResults = (props) => {
    return(
        <div className="noResults">
            <div className="card-property">
                <FaHouseDamage />
                <p>{props.text}</p>
            </div>
        </div>
    );
};

NoResults.propTypes = {
    text: PropTypes.string.isRequired
};

export default NoResults;
