import alertDispatcher from './alertDispatcher';
import * as TC from '../res/textConstants';
import {ALERT_MESSAGE_TYPE} from "./constants";

// eslint-disable-next-line
const REGEX_EMAIL = /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
const REG_EXP_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,15}$)/;

export function validateEmail(email) {
    return email.match(REGEX_EMAIL) ? true : false;
}

export function validatePassword(password) {
    return password.match(REG_EXP_PASSWORD) ? true : false;
}

function validateUsername(username) {
    if(!username) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_USERNAME);

        return {
            valid: false,
            invalidField: 'username'
        };
    }

    if(!validateEmail(username)) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.INVALID_EMAIL);

        return {
            valid: false,
            invalidField: 'username'
        };
    }

    return {
        valid: true
    };
}

function comparePasswords(password, confirmPassword) {
    const match = password === confirmPassword;

    if(!match) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.PASSWORD_MISMATCH);
    }

    return {
        valid: match,
        invalidField: match ? '' : 'confirmPassword'
    }
}

function checkValidPassword(password) {
    const validPassword = validatePassword(password);

    if(!validPassword) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.PASSWORD_INVALID, 6000);
    }

    return {
        valid: validPassword,
        invalidField: validPassword ? '' : 'password'
    }
}

function validateAuth(auth) {
    if(!auth) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.AUTH_REQUIRED, 4000);
        return {
            valid: false,
            invalidField: 'auth'
        };
    }

    return {
        valid: true
    }
}

export function validateLoginForm(username, password) {
    const usernameValidation = validateUsername(username);
    if(!usernameValidation.valid) {
        return usernameValidation;
    }

    if(!username) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_USERNAME);

        return {
            valid: false,
            invalidField: 'username'
        };
    }

    if(!validateEmail(username)) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.INVALID_EMAIL);

        return {
            valid: false,
            invalidField: 'username'
        }
    }

    if(!password) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_PASSWORD);

        return {
            valid: false,
            invalidField: 'password'
        }
    }

    return {
        valid: true
    }
}

export function validateSignupForm(fullname, username, auth) {
    if(!fullname) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_FULL_NAME);

        return {
            valid: false,
            invalidField: 'fullname'
        }
    }

    const usernameValidation = validateUsername(username);
    if(!usernameValidation.valid) {
        return usernameValidation;
    }

    const authValidation = validateAuth(auth);
    if(!authValidation.valid) {
        return authValidation;
    }

    return {
        valid: true
    };
}

export function validateForgotPasswordForm(username, auth) {
    const usernameValidation = validateUsername(username);
    if(!usernameValidation.valid) {
        return usernameValidation;
    }

    const authValidation = validateAuth(auth);
    if(!authValidation.valid) {
        return authValidation;
    }

    return {
        valid: true
    }
}

export function validatePasswordSetForm(username, password, confirmPassword) {
    const usernameValidation = validateUsername(username);
    if(!usernameValidation.valid) {
        return usernameValidation;
    }

    if(!password) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_PASSWORD);

        return {
            valid: false,
            invalidField: 'password'
        }
    }

    if(!confirmPassword) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_CONFIRM_PASSWORD);

        return {
            valid: false,
            invalidField: 'confirmPassword'
        }
    }

    const comparePasswordValidation = comparePasswords(password, confirmPassword);
    if(!comparePasswordValidation.valid) {
        return comparePasswordValidation;
    }

    const passwordValidation = checkValidPassword(password);
    if(!passwordValidation.valid) {
        return passwordValidation;
    }

    return {
        valid: true
    };
}

export function validateUpdatePasswordForm(oldPassword, newPassword, confirmPassword) {
    if(!oldPassword) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_OLD_PASSWORD);

        return {
            valid: false,
            invalidField: 'oldPassword'
        }
    }

    if(!newPassword) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_NEW_PASSWORD);

        return {
            valid: false,
            invalidField: 'newPassword'
        }
    }

    if(!confirmPassword) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_CONFIRM_PASSWORD);

        return {
            valid: false,
            invalidField: 'confirmPassword'
        }
    }

    const comparePasswordValidation = comparePasswords(newPassword, confirmPassword);
    if(!comparePasswordValidation.valid) {
        return comparePasswordValidation;
    }

    const passwordValidation = checkValidPassword(newPassword);
    if(!passwordValidation.valid) {
        return passwordValidation;
    }

    return {
        valid: true
    };
}

export function validateContactForm(message) {
    if(!message) {
        alertDispatcher(ALERT_MESSAGE_TYPE.WARNING, TC.MISSING_MESSAGE);

        return {
            valid: false,
            invalidField: 'message'
        }
    }

    return {
        valid: true
    }
}
