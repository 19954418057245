import { createStore, combineReducers } from 'redux';

import loading from '../reducers/loading';
import authedUser from '../reducers/authedUser';
import company from '../reducers/company';
import propertiesUser from '../reducers/propertiesUser';
import propertiesAgency from '../reducers/propertiesAgency';
import parameters from '../reducers/parameters';
import filtersReducer from '../reducers/filters';

import middleware from '../middleware/index';

export default () => {
    const store = createStore(
        combineReducers({
            properties: propertiesUser,
            filters: filtersReducer,
            loading,
            authedUser,
            company,
            propertiesUser,
            propertiesAgency,
            parameters
        }),
        middleware
    );

    store.getState();

    return store;
};