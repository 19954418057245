import React from 'react';
import {BACK_HOME, SIGNUP_REQUEST_SENT, VALIDATION_NEEDED_INFO_1, VALIDATION_NEEDED_INFO_2} from "../res/textConstants";
import {withNamespaces} from "react-i18next";


const SignupSuccess = (props) => {
    const goHome = () => {
        props.history.push('/');
    };

    const {t} = props;

    return (
        <div className="login">
            <div className="login-container signup-success">
                <div className="left one">
                    <h1>{t(SIGNUP_REQUEST_SENT)}</h1>
                    <div className="subtitle">
                        <p>{t(VALIDATION_NEEDED_INFO_1)}</p>
                        <p>{t(VALIDATION_NEEDED_INFO_2)}</p>
                    </div>
                    <button
                        className='m-button'
                        onClick={goHome}>
                        {t(BACK_HOME)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces('translations')(SignupSuccess);
