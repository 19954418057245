import {
    START_INITIAL_LOADING,
    END_INITIAL_LOADING,
    START_CONTENT_LOADING,
    END_CONTENT_LOADING,
    START_BUTTON_LOADING,
    END_BUTTON_LOADING
} from "../actions/loading";

export default function loading(state = {initial: 0, content: 0, button: 0}, action) {
    switch (action.type) {
        case START_INITIAL_LOADING:
            return {
                ...state,
                initial: state.initial + 1
            };

        case END_INITIAL_LOADING:
            return {
                ...state,
                initial: state.initial - 1
            };

        case START_CONTENT_LOADING:
            return {
                ...state,
                content: state.content + 1
            };

        case END_CONTENT_LOADING:
            return {
                ...state,
                content: state.content - 1
            };

        case START_BUTTON_LOADING:
            return {
                ...state,
                button: state.button + 1
            };

        case END_BUTTON_LOADING:
            return {
                ...state,
                button: state.button - 1
            };

        default:
            return state;
    }
}