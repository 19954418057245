import * as API from '../helpers/API';
import {startInitialLoading, endInitialLoading, startButtonLoading, endButtonLoading} from './loading'
import alertDispatcher from "../helpers/alertDispatcher";
import {ALERT_MESSAGE_TYPE} from "../helpers/constants";
import {clearUserProperties} from "./propertiesUser";
import {clearAgencyProperties} from "./propertiesAgency";

export const SET_AUTHED_USER = 'SET_AUTHED_USER';
export const REMOVE_AUTHED_USER = 'REMOVE_AUTHED_USER';

function setAuthedUser(authedUser) {
    return {
        type: SET_AUTHED_USER,
        authedUser
    }
}

export function handleLogin(username, password) {
    return (dispatch) => {
        dispatch(startButtonLoading());
        API.login(username, password)
        .then(userData => {
            dispatch(setAuthedUser(userData));
            dispatch(endButtonLoading());
        })
        .catch(error => {
            alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
            dispatch(endButtonLoading());
        })
    }
}

export function handleCheckAuthedUser() {
    return (dispatch) => {
        dispatch(startInitialLoading());
        API.checkIfUserLoggedIn()
        .then(userData => {
            if(userData) {
                dispatch(setAuthedUser(userData));
                dispatch(endInitialLoading());
            }
        })
        .catch(e => {
            dispatch(endInitialLoading());
        })
    }
}

function removeAuthedUser() {
    return {
        type: REMOVE_AUTHED_USER
    }
}

export function handleRemoveAuthedUser() {
    return (dispatch) => {
        dispatch(startButtonLoading());
        API.logout()
            .then(() => {
                dispatch(endButtonLoading());
                dispatch(removeAuthedUser());
                dispatch(clearUserProperties());
                dispatch(clearAgencyProperties());
            })
            .catch(error => {
                alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
                dispatch(endButtonLoading());
            })
    }
}

