import * as API from "../helpers/API";
import { startContentLoading, endContentLoading } from "./loading";
import alertDispatcher from "../helpers/alertDispatcher";
import { ALERT_MESSAGE_TYPE } from "../helpers/constants";
import {
  composePropertyLocation,
  composePropertyTitle
} from "../helpers/utils";
import { CLEAR_PROPERTIES_USER } from "./propertiesUser";

export const SET_PROPERTIES_AGENCY = "SET_PROPERTIES_AGENCY";
export const CLEAR_PROPERTIES_AGENCY = "CLEAR_PROPERTIES_AGENCY";

function setAgencyProperties(properties) {
  return {
    type: SET_PROPERTIES_AGENCY,
    properties
  };
}

export function handleSetAgencyProperties(parameters) {
  return dispatch => {
    dispatch(startContentLoading());
    API.getAgencyProperties(parameters)
      .then(data => {
        const properties = data.Properties;
        const agents = data.Properties_agents;
        const gallery = data.Properties_gallery;
        const descriptions = data.Properties_descriptions;

        properties.map(property => {
          property.gallery = gallery.filter(
            photo => photo.property_id === property.id
          );
          property.agents = agents.filter(
            agent => agent.property_id === property.id
          );
          const description = descriptions.find(
            desc => desc.property_id === property.id
          );
          property.description = description ? description.description : null;
          property.location = composePropertyLocation(
            property.location_level_1,
            property.location_level_2,
            property.location_level_3,
            property.location_level_4
          );
          property.completeLocation =
            property.location_level_1 +
            ", " +
            property.location_level_2 +
            ", " +
            property.location_level_3 +
            ", " +
            property.location_level_4;
          property.title = composePropertyTitle(
            property.type,
            property.bedrooms
          );
          delete property.location_level_1;
          delete property.location_level_2;
          delete property.location_level_3;
          delete property.location_level_4;
          return property;
        });

        dispatch(setAgencyProperties(properties));
        dispatch(endContentLoading());
      })
      .catch(error => {
        dispatch(endContentLoading());
        alertDispatcher(ALERT_MESSAGE_TYPE.ERROR, error);
      });
  };
}

export function clearAgencyProperties() {
  return {
    type: CLEAR_PROPERTIES_USER
  };
}
