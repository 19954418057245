export const LOGIN = "LOGIN";
export const USERNAME = "USERNAME";
export const PASSWORD = 'PASSWORD';
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const FULL_NAME = "FULL_NAME";
export const SIGNUP = "SIGNUP";
export const SIGNUP_NOW = "SIGNUP_NOW";
export const SIGNUP_ACTIVATION = "SIGNUP_ACTIVATION";
export const LOGIN_TERMS = "LOGIN_TERMS";
export const LOGIN_GREETING_1 = "LOGIN_GREETING_1";
export const LOGIN_GREETING_2 = "LOGIN_GREETING_2";
export const SIGNUP_GREETING_1 = "SIGNUP_GREETING_1";
export const SIGNUP_GREETING_2 = "SIGNUP_GREETING_2";
export const RECOVER_GREETING_1 = "RECOVER_GREETING_1";
export const RECOVER_GREETING_2 = "RECOVER_GREETING_2";
export const LOADING = "LOADING";
export const BACK_HOME = "BACK_HOME";
export const BACK = "BACK";
export const SEND = "SEND";
export const ACTIVATE = "ACTIVATE";
export const ACTIVATION_CODE = "ACTIVATION_CODE";
export const LOGOUT = "LOGOUT";
export const HELLO = "HELLO";
export const AGENT_TYPE = "AGENT_TYPE";
export const AGENT = "AGENT";
export const AGENTS = "AGENTS";
export const CONTACT_PHONE = "CONTACT_PHONE";
export const CONTACT_CELL_PHONE = "CONTACT_CELL_PHONE";
export const CONTACT_EMAIL = "CONTACT_EMAIL";
export const NO_ACCOUNT = "NO_ACCOUNT";
export const ALREADY_HAVE_ACCOUNT = "ALREADY_HAVE_ACCOUNT";
export const FORGOT_MY_PASSWORD = "FORGOT_MY_PASSWORD";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const DATE = "DATE";
export const CONDITION = "CONDITION";
export const VISIT_PROPERTY = "VISIT_PROPERTY";
export const VISIT_DATE = "VISIT_DATE";
export const VISIT_INTEREST = "VISIT_INTEREST";
export const VISIT_PRICE = "VISIT_PRICE";
export const VISIT_LOCATION = "VISIT_LOCATION";
export const VISIT_CONDITION = "VISIT_CONDITION";
export const VISIT_POSITIVE = "VISIT_POSITIVE";
export const VISIT_NEGATIVE = "VISIT_NEGATIVE";
export const VISIT_VIRTUAL = "VISIT_VIRTUAL";
export const WEBSITE_VIEWS = "WEBSITE_VIEWS";
export const NO_RESULTS = "NO_RESULTS";
export const OLD_PASSWORD = "OLD_PASSWORD";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const CONFIRM_NEW_PASSWORD = "CONFIRM_NEW_PASSWORD";
export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_SIGNUP = "AUTH_SIGNUP";
export const AUTH_GENERIC = "AUTH_GENERIC";
export const VALIDATION_NEEDED = "VALIDATION_NEEDED";
export const VALIDATION_NEEDED_INFO_1 = "VALIDATION_NEEDED_INFO_1";
export const VALIDATION_NEEDED_INFO_2 = "VALIDATION_NEEDED_INFO_2";
export const PROPERTY_NOT_FOUND = "PROPERTY_NOT_FOUND";
export const ACCOUNT_NOT_VERIFIED = "ACCOUNT_NOT_VERIFIED";
export const ACCOUNT_VALIDATED = "ACCOUNT_VALIDATED";
export const ACCOUNT_VALIDATED_INFO_1 = "ACCOUNT_VALIDATED_INFO_1";
export const ACCOUNT_VALIDATED_INFO_2 = "ACCOUNT_VALIDATED_INFO_2";
export const MESSAGE = "MESSAGE";
export const SIGNUP_REQUEST_SENT = "SIGNUP_REQUEST_SENT";
export const RECOVER_REQUEST_SENT = "RECOVER_REQUEST_SENT";
export const RECOVER_PASSWORD_SENT_INFO_1 = "RECOVER_PASSWORD_SENT_INFO_1";
export const RECOVER_PASSWORD_SENT_INFO_2 = "RECOVER_PASSWORD_SENT_INFO_2";
export const PROPERTIES = "PROPERTIES";
export const BUSINESS_ACTIONS = "BUSINESS_ACTIONS";
export const PROPERTY_FEATURES = "PROPERTY_FEATURES";
export const PUBLISH_WEBSITE = "PUBLISH_WEBSITE";
export const PUBLISH_PORTAL = "PUBLISH_PORTAL";
export const CONTRACT_START_DATE = "CONTRACT_START_DATE";
export const BEDROOM = "BEDROOM";
export const BEDROOMS = "BEDROOMS";
export const SEE_MORE = "SEE_MORE";
export const SEE_LESS = "SEE_LESS";
export const PROPERTY_LIST = "PROPERTY_LIST";
export const ACTIVITIES = "ACTIVITIES";
export const TYPE = "TYPE";
export const DESCRIPTION = "DESCRIPTION";
export const REFERENCE = "REFERENCE";
export const PROPERTY = "PROPERTY";
export const CLIENT_NAME = "CLIENT_NAME";
export const PRIVATE_BUSINESSES = "PRIVATE_BUSINESSES";
export const ACTIVITIES_PROPERTY = "ACTIVITIES_PROPERTY";
export const ACTIVITY_PROPERTY = "ACTIVITY_PROPERTY";
export const ACTIVITY_EMAIL = "ACTIVITY_EMAIL";
export const ACTIVITY_PHONE = "ACTIVITY_PHONE";
export const ACTIVITY_TASK = "ACTIVITY_TASK";
export const ACTIVITY_COMMITMENT = "ACTIVITY_COMMITMENT";
export const ACTIVITY_PROPOSAL = "ACTIVITY_PROPOSAL";
export const ACTIVITY_VISIT = "ACTIVITY_VISIT";
export const ACTIVITY_VIRTUAL_VISIT = "ACTIVITY_VIRTUAL_VISIT";
export const ACTIVITY_ALERT = "ACTIVITY_ALERT";
export const FEATURE_AREA = "FEATURE_AREA";
export const FEATURE_NUMBER_OF_ROOMS = "FEATURE_NUMBER_OF_ROOMS";
export const FEATURE_NUMBER_OF_BATHS = "FEATURE_NUMBER_OF_BATHS";
export const FEATURE_PRICE_PER_AREA = "FEATURE_PRICE_PER_AREA";
export const FEATURE_NUMBER_OF_GARAGES = "FEATURE_NUMBER_OF_GARAGES";
export const FEATURE_ENERGY_RATING = "FEATURE_ENERGY_RATING";
export const FEATURE_DESCRIPTION = "FEATURE_DESCRIPTION";
export const FEATURE_VIEW_DESCRIPTION = "FEATURE_VIEW_DESCRIPTION";
export const FEATURE_HIDE_DESCRIPTION = "FEATURE_HIDE_DESCRIPTION";
// FEEDBACK VALIDATION ERROR
export const MISSING_USERNAME = 'MISSING_USERNAME';
export const MISSING_PASSWORD = 'MISSING_PASSWORD';
export const MISSING_CONFIRM_PASSWORD = 'MISSING_CONFIRM_PASSWORD';
export const MISSING_FULL_NAME = 'MISSING_FULL_NAME';
export const MISSING_ACTIVATION_CODE = 'MISSING_ACTIVATION_CODE';
export const MISSING_MESSAGE = 'MISSING_MESSAGE';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const MISSING_NEW_PASSWORD = 'MISSING_NEW_PASSWORD';
export const MISSING_OLD_PASSWORD = 'MISSING_OLD_PASSWORD';
export const PASSWORD_MISMATCH = 'PASSWORD_MISMATCH';
export const PASSWORD_INVALID = 'PASSWORD_INVALID';
export const INVALID_REQUEST = 'INVALID_REQUEST';
export const EMAIL_NOT_SENT = 'EMAIL_NOT_SENT';
export const AUTH_REQUIRED = 'AUTH_REQUIRED';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const NO_SESSION = UNKNOWN_ERROR;
export const NO_COMPANY_STORED_IN_SESSION = UNKNOWN_ERROR;
export const REQUEST_DOMAIN_NOT_AUTHORIZED = UNKNOWN_ERROR;
export const TIME_OUT = "TIME_OUT";
export const EMAIL_TEMPLATE_NOT_FOUND = EMAIL_NOT_SENT;
export const MISSING_CREDENTIALS = "MISSING_CREDENTIALS";
export const WRONG_CREDENTIALS = "WRONG_CREDENTIALS";
export const NO_USER_INFO_AVAILABLE = "NO_USER_INFO_AVAILABLE";

// FEEDBACK VALIDATION SUCCESS
export const SIGNUP_ACTIVATION_SUCCESS = 'SIGNUP_ACTIVATION_SUCCESS';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';

