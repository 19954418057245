import {CLEAR_PROPERTIES_AGENCY, SET_PROPERTIES_AGENCY} from "../actions/propertiesAgency";

export default function propertiesAgency(state = [], action) {
    switch(action.type) {
        case SET_PROPERTIES_AGENCY:
            return [
                ...state,
                ...action.properties
            ];

        case CLEAR_PROPERTIES_AGENCY:
            return [];

        default:
            return state;
    }
}
